import { ReactSVG } from "react-svg";

export default function InlineSVG({
  src,
  className,
  width,
  height,
  ariaHidden = false,
  ...props
}) {
  return (
    <ReactSVG
      src={src}
      beforeInjection={(svg) => {
        svg.classList.add(className);
        svg.setAttribute("style", `width: ${width}px; height: ${height}px`);
        svg.setAttribute("aria-hidden", `${ariaHidden}`);
      }}
      {...props}
    />
  );
}
