import PropTypes from "prop-types";

export default function TabPanel(props) {
  const { children, value, index, id = index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${id}`}
      aria-labelledby={`tab-${id}`}
      {...other}
    >
      {value === index && [children]}
    </div>
  );
}

export function a11yProps(key) {
  return {
    id: `tab-${key}`,
    "aria-controls": `tabpanel-${key}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
