import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// MUI Imports
import { useNavigate, useParams } from "react-router-dom";
import {
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  MenuItem,
  Button,
  Link,
  FormControlLabel,
  Slide,
  Snackbar,
  CircularProgress,
} from "@mui/material";

// Helper Imports
import { snackSettings } from "../../../helpers/helpers";

// React Hook Form Imports
import { useForm } from "react-hook-form";

// Custom Component Imports
import BackButton from "../../../components/back-button/back-button-component";
import InputField from "../../../components/input/input-component";
import CustomCheckbox from "../../../components/checkbox/checkbox-component";
import CustomDatePicker from "../../../components/datepicker/datepicker-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";

// Icon Imports
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

// Mock Data Imports
import {
  complexityOptionsMockData,
  mockCurrencyOptions,
  opportunityTypeOptionsMockData,
  primaryContactOptionsMockData,
  entityOptionsMockData,
} from "../../../data/mock-data";
//

// Helper Imports
import { formatDate } from "../../../helpers/helpers";
import CustomerService from "../../../services/customer.service";
import UserService from "../../../services/user.service";
import OpportunityService from "../../../services/opportunity.service";
import AuthService from "../../../services/auth.service";

// New Opportunity Steps
export const newOpportunitySteps = [
  "Opportunity",
  "Product",
  "Requirements",
  "Estimate",
];

const opportunityTypeData = [
  {
    opportunityTypeID: 1,
    opportunityTypeName: "New",
  },
  {
    opportunityTypeID: 2,
    opportunityTypeName: "Upgrade",
  },
];

export default function Step1Opportunity() {
  const navigate = useNavigate();
  const { opportunityID } = useParams();
  const { user_id, full_name } = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: opportunityID
      ? async () => {
          const response = await OpportunityService.getOpportunityByID(
            opportunityID
          ).catch((error) => {
            handleServerError(error);
          });
          const {
            customerID,
            billingAccountID,
            opportunityName,
            opportunityDescription,
            opportunityDisplayID,
            opportunityOwnerName,
            opportunityTypeID,
            primaryContactID,
            closeDate,
          } = response.data;
          setOpportunity(response.data);
          setOpportunityRefID(opportunityDisplayID);
          setSelectedCustomer(customerID);
          setSelectedBillingAccount(billingAccountID);
          setOpportunityOwner(opportunityOwnerName);
          setSelectedDealType(opportunityTypeID);
          setSelectedPrimaryContact(primaryContactID);
          setSelectedDate(closeDate);
          return {
            opportunityName,
            opportunityDescription,
          };
        }
      : {},
  });

  // handle form submission
  const onSubmit = async (data) => {
    const isValid = await trigger();
    setIsLoading(true);

    if (isValid) {
      if (opportunityID) {
        // Update opportunity
        const updateOpportunity = {
          ...opportunity,
          ...data,
          closeDate: formatedDate,
          currencyCode: "ZAR",
          sellingEntityCode: "SA",
          deliveryEntityCode: "VC",
          accountLeadRegionCode: "GP",
          opportunityRegionCode: "RSA",
          customerID: selectedCustomer,
          billingAccountID: selectedBillingAccount,
          opportunityOwnerName: opportunityOwner,
          opportunityTypeID: selectedDealType,
          primaryContactID: selectedPrimaryContact,
        };
        OpportunityService.updateOpportunity(opportunityID, updateOpportunity)
          .then(() => {
            // Go to the next step
            navigate(`/new-opportunity/${opportunityID}/product`);
          })
          .catch((error) => {
            setIsLoading(false);
            handleServerError(error);
          });
      } else {
        // Creating a new opportunity
        const opportunityData = {
          ...data,
          step: "Opportunity",
          opportunityOwnerID: user_id,
          currencyCode: "ZAR",
          sellingEntityCode: "SA",
          deliveryEntityCode: "VC",
          accountLeadRegionCode: "GP",
          opportunityRegionCode: "RSA",
          closeDate: formatedDate,
          salesAgentID: user_id,
          opportunitySize: null,
          customerBudget: null,
          opportunityJSON: null,
          customerID: selectedCustomer,
          billingAccountID: selectedBillingAccount,
          opportunityOwnerName: opportunityOwner,
          opportunityTypeID: selectedDealType,
          primaryContactID: selectedPrimaryContact,
        };

        OpportunityService.createOpportunity(opportunityData)
          .then((response) => {
            const { opportunityID: newOpportunityID } = response.data;
            // Go to the next step
            navigate(`/new-opportunity/${newOpportunityID}/product`);
          })
          .catch((error) => {
            setIsLoading(false);
            handleServerError(error);
          });
      }
    }
  };

  // Data used in the page
  const [opportunity, setOpportunity] = useState({});

  const [opportunityRefID, setOpportunityRefID] = useState("N/A");
  const [serviceRequestID, setServiceRequestID] = useState("N/A");
  const [opportunityOwner, setOpportunityOwner] = useState(full_name);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedBillingAccount, setSelectedBillingAccount] = useState("");
  const [selectedDealType, setSelectedDealType] = useState("");
  const [primaryContacts, setPrimaryContacts] = useState([]);
  const [selectedPrimaryContact, setSelectedPrimaryContact] = useState(user_id);

  // datepicker
  // get date ad format it like this "01/01/2024"
  const [selectedDate, setSelectedDate] = useState(new Date() || null);

  // Checkbox State
  const [commOpsChecked, setCommOpsChecked] = useState(false);

  // handle date change event
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  // helper function to format date
  const formatedDate = formatDate(selectedDate);

  // Get Customers
  useEffect(() => {
    CustomerService.getAllCustomers().then((response) => {
      setCustomers(response.data);
    });
  }, []);

  // Get Primary Contacts
  useEffect(() => {
    UserService.getAllUsers().then((response) => {
      setPrimaryContacts(response.data);
    });
  }, []);

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 400) {
      // Warning
      handleGenericSnackOpen("warning", errors[0].message);
    } else if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/");
    } else if (status === 403) {
      // Unauthorized
      handleGenericSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleGenericSnackOpen("notFound", errors[0].message);
    } else {
      if (errors) {
        // Unexpected server error
        handleGenericSnackOpen("error", errors[0].message);
      } else {
        handleGenericSnackOpen(
          "error",
          `Unexpected server error. Code: {${error.code}}; Message: {${error.message}}`
        );
      }
    }
  };
  return (
    <>
      <div className="container col-start-1 col-end-13 row-start-1 my-6 mx-auto px-4 lg:col-start-2 lg:col-end-12 lg:my-8 lg:px-0">
        <BackButton to="/home"></BackButton>
      </div>

      <section
        aria-label="Step 1 - Opportunity"
        className="container col-start-1 col-end-13 row-start-2 mx-auto px-4 lg:col-start-2 lg:col-end-12 lg:px-0"
      >
        <div className="my-8 grid grid-cols-10">
          <Stepper
            activeStep={0}
            alternativeLabel
            className="col-start-1 col-end-11 lg:col-start-2 lg:col-end-10"
          >
            {newOpportunitySteps.map((label) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={(props) => (
                    <StepIcon
                      {...props}
                      active={props.active || props.completed}
                      completed={false}
                    />
                  )}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>

        <form
          className="my-6 grid grid-cols-10 gap-4 lg:my-12"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="col-start-1 col-end-11 rounded-2xl bg-white py-4 px-6 shadow md:col-start-2 md:col-end-10 md:px-8 lg:px-10 lg:py-6 xl:col-start-3 xl:col-end-9 xl:px-12 xl:py-8">
            <h2 className="mb-3 text-base font-bold text-red-100">
              Customer Details
            </h2>
            <hr className="mb-4 border-grey-100" />
            <div className="mb-4">
              <InputField
                select
                inputLabel="Customer"
                defaultValue=""
                value={selectedCustomer}
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                }}
                InputProps={{
                  ...register("customerID", {
                    required: "Customer selection is required",
                  }),
                  onChange: (e) => {
                    setSelectedCustomer(e.target.value);
                  },
                }}
                error={Boolean(errors.customerID)}
                helperText={errors.customerID?.message}
              >
                <MenuItem disabled value="">
                  - Select -
                </MenuItem>
                {customers.map((option) => (
                  <MenuItem key={option.customerID} value={option.customerID}>
                    {option.customerName}
                  </MenuItem>
                ))}
              </InputField>
            </div>
            <div className="mb-4">
              <InputField
                select
                inputLabel="Billing Account"
                defaultValue=""
                value={selectedBillingAccount}
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                }}
                InputProps={{
                  ...register("billingAccountID", {
                    required: "Billing account selection is required",
                  }),
                  onChange: (e) => {
                    setSelectedBillingAccount(e.target.value);
                  },
                }}
                error={Boolean(errors.billingAccountID)}
                helperText={errors.billingAccountID?.message}
              >
                <MenuItem disabled value="">
                  - Select -
                </MenuItem>
                {customers
                  .find((v) => v.customerID === selectedCustomer)
                  ?.billingAccounts.map((option) => (
                    <MenuItem
                      key={option.billingAccountID}
                      value={option.billingAccountID}
                    >
                      {option.accountName}
                    </MenuItem>
                  ))}
              </InputField>
            </div>
          </div>

          <div className="col-start-1 col-end-11 rounded-2xl bg-white py-4 px-6 shadow md:col-start-2 md:col-end-10 md:px-8 lg:px-10 lg:py-6 xl:col-start-3 xl:col-end-9 xl:px-12 xl:py-8">
            <h2 className="mb-3 text-base font-bold text-red-100">
              Primary Opportunity Details
            </h2>
            <hr className="mb-4 border-grey-100" />

            <div className="mb-4 flex flex-col gap-4 sm:flex-row sm:justify-between">
              <div className="flex flex-col gap-1">
                <span className="font-bold">Opportunity Ref. ID</span>
                <span className="text-grey-300">{opportunityRefID}</span>
              </div>

              <div className="flex flex-col gap-1">
                <span className="font-bold">Service Request ID</span>
                <span className="text-grey-300">{serviceRequestID}</span>
              </div>
            </div>

            <div className="mb-4">
              <InputField
                inputLabel="Opportunity Name"
                placeholder="Enter opportunity name..."
                defaultValue=""
                InputProps={{
                  ...register("opportunityName", {
                    required: "Opportunity name is required",
                  }),
                  onBlur: () => trigger("opportunityName"),
                }}
                error={Boolean(errors.opportunityName)}
                helperText={errors.opportunityName?.message}
              />
            </div>

            <div className="mb-4">
              <InputField
                inputLabel="Description"
                placeholder="Enter description..."
                defaultValue=""
                InputProps={{
                  ...register("opportunityDescription", {
                    required: "Description is required",
                  }),
                  onBlur: () => trigger("opportunityDescription"),
                }}
                error={Boolean(errors.opportunityDescription)}
                helperText={errors.opportunityDescription?.message}
              />
            </div>

            <div className="mb-4">
              <InputField
                inputLabel="Opportunity Owner"
                value={opportunityOwner}
                disabled
              />
            </div>
            <div className="mb-4">
              <InputField
                select
                disabled
                inputLabel="Opportunity Type"
                defaultValue=""
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                }}
              >
                <MenuItem disabled value="">
                  - Select -
                </MenuItem>
                {opportunityTypeOptionsMockData.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            </div>
            <div className="mb-4">
              <InputField
                select
                disabled
                inputLabel="Opportunity Currency"
                defaultValue="zar"
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                }}
                InputProps={{
                  ...register("currencyCode"),
                }}
              >
                <MenuItem disabled value="">
                  - Select -
                </MenuItem>
                {mockCurrencyOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            </div>

            <div className="mb-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
              <InputField
                select
                disabled
                inputLabel="Selling Entity"
                defaultValue=""
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                }}
              >
                <MenuItem disabled value="">
                  - Select -
                </MenuItem>
                {entityOptionsMockData.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
              <InputField
                select
                disabled
                inputLabel="Delivery Entity"
                defaultValue=""
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                }}
              >
                <MenuItem disabled value="">
                  - Select -
                </MenuItem>
                {entityOptionsMockData.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            </div>
            <div className="mb-4">
              <InputField
                select
                disabled
                inputLabel="Account Lead Region"
                defaultValue=""
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),

                  displayEmpty: true,
                }}
              >
                <MenuItem disabled value="">
                  - Select -
                </MenuItem>
                {mockCurrencyOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            </div>
            <div className="mb-4">
              <InputField
                select
                disabled
                inputLabel="Opportunity Region"
                defaultValue=""
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                }}
              >
                <MenuItem disabled value="">
                  - Select -
                </MenuItem>
                {mockCurrencyOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            </div>
          </div>

          <div className="col-start-1 col-end-11 rounded-2xl bg-white py-4 px-6 shadow md:col-start-2 md:col-end-10 md:px-8 lg:px-10 lg:py-6 xl:col-start-3 xl:col-end-9 xl:px-12 xl:py-8">
            <h2 className="mb-3 text-base font-bold text-red-100">
              Sales Process
            </h2>
            <hr className="mb-4 border-grey-100" />

            <div className="mb-4">
              <InputField
                select
                inputLabel="Deal Type"
                defaultValue=""
                value={selectedDealType}
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                }}
                InputProps={{
                  ...register("opportunityTypeID", {
                    required: "Deal type selection is required",
                  }),
                  onChange: (e) => {
                    setSelectedDealType(e.target.value);
                  },
                }}
                error={Boolean(errors.opportunityTypeID)}
                helperText={errors.opportunityTypeID?.message}
              >
                <MenuItem disabled value="">
                  - Select -
                </MenuItem>
                {opportunityTypeData.map((option) => (
                  <MenuItem
                    key={option.opportunityTypeID}
                    value={option.opportunityTypeID}
                  >
                    {option.opportunityTypeName}
                  </MenuItem>
                ))}
              </InputField>
            </div>
            <div className="mb-4">
              <CustomDatePicker
                value={selectedDate}
                handleDateChange={(date) => {
                  handleDateChange(date);
                }}
                label="Close date"
              />
            </div>
            <div className="mb-4">
              <InputField
                select
                inputLabel="Primary Contact"
                defaultValue=""
                value={selectedPrimaryContact}
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                }}
                InputProps={{
                  ...register("primaryContactID", {
                    required: "Primary contact selection is required",
                  }),
                  onChange: (e) => {
                    setSelectedPrimaryContact(e.target.value);
                  },
                }}
                error={Boolean(errors.primaryContactID)}
                helperText={errors.primaryContactID?.message}
              >
                <MenuItem disabled value="">
                  - Select -
                </MenuItem>
                {primaryContacts.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.firstName} {option.surname}
                  </MenuItem>
                ))}
              </InputField>
            </div>
            <div className="mb-4 grid grid-cols-1 gap-4 lg:grid-cols-2">
              <InputField
                disabled
                inputLabel="Probability"
                placeholder="0%"
                defaultValue=""
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                }}
                InputProps={{
                  ...register("probability", {
                    //required: "Probability is required",
                  }),
                  onBlur: () => trigger("probability"),
                }}
                error={Boolean(errors.probability)}
                helperText={errors.probability?.message}
              ></InputField>
              <InputField
                disabled
                select
                inputLabel="Complexity"
                defaultValue=""
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                }}
                InputProps={{
                  ...register("complexity_option", {
                    //required: "Complexity selection is required",
                  }),
                  onBlur: () => trigger("complexity_option"),
                }}
                error={Boolean(errors.complexity_option)}
                helperText={errors.complexity_option?.message}
              >
                <MenuItem disabled value="">
                  - Select -
                </MenuItem>
                {complexityOptionsMockData.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            </div>
            <div className="mb-4">
              <InputField
                select
                disabled
                inputLabel="Digital Sales Agent"
                defaultValue=""
                SelectProps={{
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  displayEmpty: true,
                }}
              >
                <MenuItem disabled value="">
                  - Select -
                </MenuItem>
                {primaryContactOptionsMockData.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </InputField>
            </div>

            <FormControlLabel
              control={
                <CustomCheckbox
                  checked={commOpsChecked}
                  disabled
                  onChange={() => setCommOpsChecked(!commOpsChecked)}
                />
              }
              label="CommOps Top Renewal"
            />
          </div>

          <div className="col-span-10 row-start-5 sm:col-span-5 sm:col-start-1 sm:row-start-4 md:col-span-3 md:col-start-4 lg:col-span-2 lg:col-start-6 xl:col-span-1 xl:col-start-7">
            <Button
              variant="text"
              color="secondary"
              className="w-full"
              LinkComponent={Link}
              to={-1}
              disableFocusRipple
            >
              Cancel
            </Button>
          </div>
          <div className="relative col-span-10 row-start-4 mt-4 sm:col-span-5 sm:col-start-6 sm:mt-0 md:col-span-3 md:col-start-7 lg:col-span-2 lg:col-start-8 xl:col-span-1 xl:col-start-8">
            <Button
              type="submit"
              variant="contained"
              disableFocusRipple
              className="w-full"
              disabled={isLoading}
            >
              Next
            </Button>
            {isLoading && (
              <div className="absolute top-1/2 left-1/2 mt-[3px] -translate-x-1/2 -translate-y-1/2">
                <CircularProgress
                  size={30}
                  thickness={4}
                  className="text-grey-700"
                />
              </div>
            )}
          </div>
        </form>
      </section>

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}
