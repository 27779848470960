import axios from "axios";

const API_URL = "/api/customers/";

const getAllCustomers = () => {
  return axios.get(API_URL);
};

const CustomerService = {
  getAllCustomers,
};

export default CustomerService;
