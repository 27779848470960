// Icon Imports
import TickOutlineCircle from "../assets/icons/tick-circle-outline-icon.svg";
import ErrorCircleNoFill from "../assets/icons/error-circle-no-fill-icon.svg";

/**
 * Returns the severity based on the given status.
 * @param {string} status - The status value.
 * @returns {string} The severity value.
 */
export const getSeverityFromStatus = (status) => {
  // Convert status to lowercase for case-insensitive comparison
  const lowercaseStatus = status.toLowerCase();

  switch (lowercaseStatus) {
    case "accepted":
      return "success";
    case "declined":
      return "error";
    case "pending":
      return "warning";
    case "initiating":
      return "warning";
    case "initiated":
      return "success";
    case "estimating":
      return "warning";
    case "estimated":
      return "success";
    case "studying":
      return "warning";
    case "studied":
      return "success";
    case "quoting":
      return "warning";
    case "quoted":
      return "success";
    case "ordering":
      return "warning";
    case "ordered":
      return "success";
    default:
      return "default";
  }
};

// formate dates
export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

// Number masking state
export const maskOptions = {
  mask: "R num",
  blocks: {
    num: {
      mask: Number,
      scale: 2,
      thousandsSeparator: ",",
      padFractionalZeros: true,
      normalizeZeros: true,
      radix: ".",
    },
  },
};

// Masking Options to only allow numeric values
export const maskNumberOnlyOptions = {
  mask: Number,
  scale: 0,
  thousandsSeparator: "",
  padFractionalZeros: false,
  normalizeZeros: true,
  radix: ".",
  mapToRadix: ["."],
  min: 0,
  max: Infinity,
  signed: false,
};

// Snackbar settings
export const snackSettings = {
  success: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-light-green-gradient-start to-light-green-gradient-end",
    icon: TickOutlineCircle,
  },
  warning: {
    colorClass: "rounded-lg bg-yellow-200",
    icon: ErrorCircleNoFill,
  },
  unauthorized: {
    colorClass: "rounded-lg bg-yellow-200",
    icon: ErrorCircleNoFill,
  },
  notFound: {
    colorClass: "rounded-lg bg-grey-800",
    icon: ErrorCircleNoFill,
  },
  error: {
    colorClass:
      "rounded-lg bg-gradient-to-b from-red-gradient-start to-red-gradient-end",
    icon: ErrorCircleNoFill,
  },
};
