import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/footer/footer-component";

export default function AuthLayout() {
  const { hostname } = window.location;
  const welcomeText = hostname === "condorwebtests.link" ? "Welcome" : "Welcome to the Vodacom Business Portal";
  return (
    <div className="flex min-h-screen flex-col bg-gradient-green-to-blue">
      <main className="container mx-auto xl:mt-auto grid grid-cols-12 items-center gap-4 sm:gap-8 p-4 sm:p-8 xl:gap-x-16 xl:p-12">
        <div className="col-start-1 col-end-13 xl:col-end-9 xl:pr-12">
          <h1 className="text-center text-3xl sm:text-5xl md:text-6xl font-semibold leading-[115%] text-white xl:text-left lg:text-8xl">
            {welcomeText}
          </h1>
        </div>
        <div className="col-start-1 xl:col-start-9 col-end-13">
          <Outlet />
        </div>
      </main>

      <Footer />
    </div>
  );
}
