export default function CustomerViewTable({ data, className }) {
  // calculate recurring subtotal
  const recurringSubtotal = data.reduce((acc, curr) => acc + curr.recurring, 0);

  // calculate non-recurring subtotal
  const nonRecurringSubtotal = data.reduce(
    (acc, curr) => acc + curr.non_recurring,
    0
  );
  // mock vat
  const vat = 0.15;

  return (
    <div
      className={`relative overflow-x-auto ${className}`}
      role="table"
      aria-label="Customer view table"
    >
      <table
        className="w-full min-w-max md:min-w-0 table-auto rounded-2xl border-0 bg-white px-1 pb-4 text-left text-base text-black"
        role="grid"
      >
        <thead className="border-b border-gray-100 bg-white text-base">
          <tr role="row">
            <th
              scope="col"
              className="py-3 text-black md:w-3/5 lg:w-[76%]"
              role="columnheader"
            >
              <h1 className="font-base font-semibold">Description</h1>
            </th>
            <th scope="col" className="px-4 py-3" role="columnheader">
              Recurring
            </th>
            <th
              scope="col"
              className="px-4 py-3"
              role="columnheader"
            >
              Non-Recurring
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className="border-b border-gray-100" role="row">
              <td className="py-3" role="cell">
                <p className="font-semibold">{item.product}</p>
              </td>
              <td className="px-4 py-3" role="cell">
                R {item.recurring}
              </td>
              <td className="px-4 py-3" role="cell">
                R {item.non_recurring}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot className="mt-2">
          <tr className="font-semibold" role="row">
            <th scope="row" className="py-3 text-base" role="cell">
              Sub-Total
            </th>
            <td className="px-4 py-3 font-normal" role="cell">
              R {recurringSubtotal}
            </td>
            <td className="px-4 py-3 font-normal" role="cell">
              R {nonRecurringSubtotal}
            </td>
          </tr>
          <tr className="font-semibold" role="row">
            <th scope="row" className="py-3 text-base" role="cell">
              VAT
            </th>
            <td className="px-4 py-3 font-normal" role="cell">
              R {(recurringSubtotal * vat).toFixed(2)}
            </td>
            <td className="px-4 py-3 font-normal" role="cell">
              R {(nonRecurringSubtotal * vat).toFixed(2)}
            </td>
          </tr>
          <tr className="font-semibold" role="row">
            <th
              scope="row"
              className="py-3 text-base text-red-100"
              role="cell"
            >
              Total (incl. VAT)
            </th>
            <td className="px-4 py-3" role="cell">
              R {(recurringSubtotal + recurringSubtotal * vat).toFixed(2)}
            </td>
            <td className="px-4 py-3" role="cell">
              R {nonRecurringSubtotal + nonRecurringSubtotal * vat}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
