import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

// MUI Imports
import {
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  Tab,
  Tabs,
  Button,
  Link,
  Slide,
  Snackbar,
  CircularProgress,
} from "@mui/material";

// Helper Imports
import { snackSettings } from "../../../helpers/helpers";

// Service Imports
import { socket } from "../../../App";

// Custom Component Imports
import BackButton from "../../../components/back-button/back-button-component";
import TabPanel, {
  a11yProps,
} from "../../../components/tab-panel/tab-panel-component";
import InputField from "../../../components/input/input-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";

// Image Import
import Lottie from "lottie-react";
import AstronautAnimation from "../../../assets/animations/astronaut-animation.json";

// Steps Import
import { newOpportunitySteps } from "../step-1-opportunity/step-1-opportunity-page";

// Mock Data Import
import OpportunityService from "../../../services/opportunity.service";
import AuthService from "../../../services/auth.service";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, getMessage } from "../../../slices/message";

export default function Step4Estimate() {
  const location = useLocation();
  // Product from Params
  const { opportunityID, product } = useParams();

  // Navigate
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!opportunityID) {
    navigate("/home");
  }

  const [pageRefresh, setPageRefresh] = useState(0);

  useEffect(() => {
    socket.on("system-action", handleSystemAction);
    return () => {
      socket.off("system-action");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSystemAction = ({ action }) => {
    if (action === "refresh-page") {
      setIsLoading(true);
      setPageRefresh(pageRefresh + 1);
      dispatch(clearMessage());
    }
  };

  const [isLoading, setIsLoading] = useState(true);
  const { message } = useSelector(getMessage);
  const [submitIsLoading, setSubmitIsLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [offeringData, setOfferingData] = useState([]);
  const [termsGenerated, setTermsGenerated] = useState([]);
  const [dataFound, setDataFound] = useState(false);

  useEffect(() => {
    if (message !== "") {
      handleGenericSnackOpen("success", message);
    } else {
      OpportunityService.getOpportunityEstimation(opportunityID)
        .then((response) => {
          const { siteData, offeringData } = response.data;
          setProductData(siteData || []);
          setOfferingData(offeringData || []);
          setIsLoading(false);
          setTermsGenerated(offeringData?.map((item) => item.term));
          setDataFound(offeringData?.length > 0);
        })
        .catch((error) => {
          setIsLoading(false);
          handleServerError(error);
        });
    }
  }, [pageRefresh]);

  const [value, setValue] = useState(0);
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const handleSubmitForApproval = () => {
    setSubmitIsLoading(true);
    // submit and go back to home page
    OpportunityService.submitForApproval(opportunityID)
      .then(() => {
        navigate("/home");
      })
      .catch((error) => {
        setSubmitIsLoading(false);
        handleServerError(error);
      });
  };

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");

  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 400) {
      // Warning
      handleGenericSnackOpen("warning", errors[0].message);
    } else if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/");
    } else if (status === 403) {
      // Unauthorized
      handleGenericSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleGenericSnackOpen("notFound", errors[0].message);
    } else {
      if (errors) {
        // Unexpected server error
        handleGenericSnackOpen("error", errors[0].message);
      } else {
        handleGenericSnackOpen(
          "error",
          `Unexpected server error. Code: {${error.code}}; Message: {${error.message}}`
        );
      }
    }
  };
  return (
    <>
      <div className="container col-start-1 col-end-13 row-start-1 my-6 mx-auto px-4 lg:col-start-2 lg:col-end-12 lg:my-8 lg:px-0">
        <BackButton
          to={`/new-opportunity/${opportunityID}/requirements/${product}`}
        ></BackButton>
      </div>

      <section
        aria-label="Step 4 - Estimate"
        className="container col-start-1 col-end-13 row-start-2 mx-auto px-4 lg:col-start-2 lg:col-end-12 lg:px-0"
      >
        <div className="my-8 grid grid-cols-10">
          <Stepper
            activeStep={3}
            alternativeLabel
            className="col-start-1 col-end-11 lg:col-start-2 lg:col-end-10"
          >
            {newOpportunitySteps.map((label) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={(props) => (
                    <StepIcon
                      {...props}
                      active={props.active || props.completed}
                      completed={false}
                    />
                  )}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {isLoading ? (
          <>Loading...</>
        ) : !dataFound ? (
          <>
            <div className="flex items-center justify-center">
              <Lottie
                animationData={AstronautAnimation}
                aria-hidden="true"
                role="presentation"
                className="max-w-2xl"
              />
            </div>
          </>
        ) : (
          <>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Product Term tabs"
              className="mt-8 mb-6 px-2"
            >
              <Tab
                label="12 Months"
                {...a11yProps(0)}
                disableRipple
                className={termsGenerated.includes(12) ? "" : "hidden"}
              />
              <Tab
                label="24 Months"
                {...a11yProps(1)}
                disableRipple
                className={termsGenerated.includes(24) ? "" : "hidden"}
              />
              <Tab
                label="36 Months"
                {...a11yProps(2)}
                disableRipple
                className={termsGenerated.includes(36) ? "" : "hidden"}
              />
              <Tab
                label="48 Months"
                {...a11yProps(3)}
                disableRipple
                className={termsGenerated.includes(48) ? "" : "hidden"}
              />
            </Tabs>

            <TabPanel value={value} index={0}>
              <div className="relative mb-6 grid grid-cols-12 gap-4">
                <div className="col-span-12 rounded-2xl bg-white p-6 shadow md:p-8 lg:col-span-8 lg:p-10 xl:col-span-9 xl:p-12">
                  <div className="mb-2 text-center sm:text-end"></div>
                  {productData.map(({ siteName, description }) => {
                    return (
                      <div key={siteName} className="my-6 grid">
                        <h1 className="mb-4 text-xl font-semibold text-red-100">
                          {siteName}
                        </h1>

                        <h2 className="mb-3 text-base font-semibold">
                          Description
                        </h2>
                        <hr className="mb-1 border-grey-100" />
                        {description
                          .filter((item) => item.term === 12)
                          .map(({ product }) => {
                            return (
                              <p
                                key={product}
                                className="border-0 border-b border-b-grey-100 py-3"
                              >
                                {product}
                              </p>
                            );
                          })}
                      </div>
                    );
                  })}

                  <InputField
                    inputLabel="Notes"
                    multiline
                    aria-label="notes text area"
                    placeholder="Enter your notes here..."
                    rows={4}
                  />
                </div>
                {offeringData.find(({ term }) => term === 12) && (
                  <aside className="col-start-1 col-end-13 lg:col-start-9 xl:col-start-10">
                    <div className="flex flex-col flex-wrap gap-4 lg:sticky lg:top-[110px] lg:px-4">
                      <div className="flex w-full flex-col items-center gap-4 rounded-2xl bg-white p-10 shadow-xl">
                        <span className="bg-gradient-to-b from-light-green-gradient-start to-light-green-gradient-end bg-clip-text text-5xl font-bold text-transparent">
                          {`${
                            offeringData.find(({ term }) => term === 12)
                              ?.efficiency
                          }%`}
                        </span>
                        <h3 className="text-lg font-semibold">
                          A&R Efficiency
                        </h3>
                      </div>
                      <div className="flex w-full flex-col rounded-2xl bg-white p-8 shadow-xl">
                        <div className="mb-6 flex flex-col justify-between  lg:flex-row lg:items-center ">
                          <p className="font-semibold">Discount</p>
                          <span> R 0.00</span>
                        </div>
                        <div className="mb-6 flex flex-col justify-between  lg:flex-row lg:items-center ">
                          <p className="font-semibold">Sub-Total</p>
                          <span>
                            {offeringData
                              .find(({ term }) => term === 12)
                              ?.totalOfferingExVatDiscounted.toLocaleString(
                                "en-ZA",
                                {
                                  style: "currency",
                                  currency: "ZAR",
                                }
                              )}
                          </span>
                        </div>
                        <div className="mb-6 flex flex-col justify-between  lg:flex-row lg:items-center ">
                          <p className="font-semibold">Vat</p>
                          <span>
                            {offeringData
                              .find(({ term }) => term === 12)
                              ?.vatAmount.toLocaleString("en-ZA", {
                                style: "currency",
                                currency: "ZAR",
                              })}
                          </span>
                        </div>
                        <div className="flex flex-col justify-between  lg:flex-row lg:items-center">
                          <p className="font-semibold text-red-100">Total</p>
                          <span className="text-red-100">
                            {(
                              (offeringData.find(({ term }) => term === 12)
                                ?.totalOfferingExVatDiscounted || 0) +
                              (offeringData.find(({ term }) => term === 12)
                                ?.vatAmount || 0)
                            ).toLocaleString("en-ZA", {
                              style: "currency",
                              currency: "ZAR",
                            })}
                          </span>
                        </div>
                      </div>
                    </div>
                  </aside>
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="relative mb-6 grid grid-cols-12 gap-4">
                <div className="col-span-12 rounded-2xl bg-white p-6 shadow md:p-8 lg:col-span-8 lg:p-10 xl:col-span-9 xl:p-12">
                  <div className="mb-2 text-center sm:text-end"></div>
                  {productData.map(({ siteName, description }) => {
                    return (
                      <div key={siteName} className="my-6 grid">
                        <h1 className="mb-4 text-xl font-semibold text-red-100">
                          {siteName}
                        </h1>

                        <h2 className="mb-3 text-base font-semibold">
                          Description
                        </h2>
                        <hr className="mb-1 border-grey-100" />
                        {description
                          .filter((item) => item.term === 24)
                          .map(({ product }) => {
                            return (
                              <p
                                key={product}
                                className="border-0 border-b border-b-grey-100 py-3"
                              >
                                {product}
                              </p>
                            );
                          })}
                      </div>
                    );
                  })}

                  <InputField
                    inputLabel="Notes"
                    multiline
                    aria-label="notes text area"
                    placeholder="Enter your notes here..."
                    rows={4}
                  />
                </div>
                {offeringData.find(({ term }) => term === 24) && (
                  <aside className="col-start-1 col-end-13 lg:col-start-9 xl:col-start-10">
                    <div className="flex flex-col flex-wrap gap-4 lg:sticky lg:top-[110px] lg:px-4">
                      <div className="flex w-full flex-col items-center gap-4 rounded-2xl bg-white p-10 shadow-xl">
                        <span className="bg-gradient-to-b from-light-green-gradient-start to-light-green-gradient-end bg-clip-text text-5xl font-bold text-transparent">
                          {`${
                            offeringData.find(({ term }) => term === 24)
                              ?.efficiency
                          }%`}
                        </span>
                        <h3 className="text-lg font-semibold">
                          A&R Efficiency
                        </h3>
                      </div>
                      <div className="flex w-full flex-col rounded-2xl bg-white p-8 shadow-xl">
                        <div className="mb-6 flex flex-col justify-between  lg:flex-row lg:items-center ">
                          <p className="font-semibold">Discount</p>
                          <span> R 0.00</span>
                        </div>
                        <div className="mb-6 flex flex-col justify-between  lg:flex-row lg:items-center ">
                          <p className="font-semibold">Sub-Total</p>
                          <span>
                            {offeringData
                              .find(({ term }) => term === 24)
                              ?.totalOfferingExVatDiscounted.toLocaleString(
                                "en-ZA",
                                {
                                  style: "currency",
                                  currency: "ZAR",
                                }
                              )}
                          </span>
                        </div>
                        <div className="mb-6 flex flex-col justify-between  lg:flex-row lg:items-center ">
                          <p className="font-semibold">Vat</p>
                          <span>
                            {offeringData
                              .find(({ term }) => term === 24)
                              ?.vatAmount.toLocaleString("en-ZA", {
                                style: "currency",
                                currency: "ZAR",
                              })}
                          </span>
                        </div>
                        <div className="flex flex-col justify-between  lg:flex-row lg:items-center">
                          <p className="font-semibold text-red-100">Total</p>
                          <span className="text-red-100">
                            {(
                              (offeringData.find(({ term }) => term === 24)
                                ?.totalOfferingExVatDiscounted || 0) +
                              (offeringData.find(({ term }) => term === 24)
                                ?.vatAmount || 0)
                            ).toLocaleString("en-ZA", {
                              style: "currency",
                              currency: "ZAR",
                            })}
                          </span>
                        </div>
                      </div>
                    </div>
                  </aside>
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="relative mb-6 grid grid-cols-12 gap-4">
                <div className="col-span-12 rounded-2xl bg-white p-6 shadow md:p-8 lg:col-span-8 lg:p-10 xl:col-span-9 xl:p-12">
                  <div className="mb-2 text-center sm:text-end"></div>
                  {productData.map(({ siteName, description }) => {
                    return (
                      <div key={siteName} className="my-6 grid">
                        <h1 className="mb-4 text-xl font-semibold text-red-100">
                          {siteName}
                        </h1>

                        <h2 className="mb-3 text-base font-semibold">
                          Description
                        </h2>
                        <hr className="mb-1 border-grey-100" />
                        {description
                          .filter((item) => item.term === 36)
                          .map(({ product }) => {
                            return (
                              <p
                                key={product}
                                className="border-0 border-b border-b-grey-100 py-3"
                              >
                                {product}
                              </p>
                            );
                          })}
                      </div>
                    );
                  })}

                  <InputField
                    inputLabel="Notes"
                    multiline
                    aria-label="notes text area"
                    placeholder="Enter your notes here..."
                    rows={4}
                  />
                </div>
                {offeringData.find(({ term }) => term === 36) && (
                  <aside className="col-start-1 col-end-13 lg:col-start-9 xl:col-start-10">
                    <div className="flex flex-col flex-wrap gap-4 lg:sticky lg:top-[110px] lg:px-4">
                      <div className="flex w-full flex-col items-center gap-4 rounded-2xl bg-white p-10 shadow-xl">
                        <span className="bg-gradient-to-b from-light-green-gradient-start to-light-green-gradient-end bg-clip-text text-5xl font-bold text-transparent">
                          {`${
                            offeringData.find(({ term }) => term === 36)
                              ?.efficiency
                          }%`}
                        </span>
                        <h3 className="text-lg font-semibold">
                          A&R Efficiency
                        </h3>
                      </div>
                      <div className="flex w-full flex-col rounded-2xl bg-white p-8 shadow-xl">
                        <div className="mb-6 flex flex-col justify-between  lg:flex-row lg:items-center ">
                          <p className="font-semibold">Discount</p>
                          <span> R 0.00</span>
                        </div>
                        <div className="mb-6 flex flex-col justify-between  lg:flex-row lg:items-center ">
                          <p className="font-semibold">Sub-Total</p>
                          <span>
                            {offeringData
                              .find(({ term }) => term === 36)
                              ?.totalOfferingExVatDiscounted.toLocaleString(
                                "en-ZA",
                                {
                                  style: "currency",
                                  currency: "ZAR",
                                }
                              )}
                          </span>
                        </div>
                        <div className="mb-6 flex flex-col justify-between  lg:flex-row lg:items-center ">
                          <p className="font-semibold">Vat</p>
                          <span>
                            {offeringData
                              .find(({ term }) => term === 36)
                              ?.vatAmount.toLocaleString("en-ZA", {
                                style: "currency",
                                currency: "ZAR",
                              })}
                          </span>
                        </div>
                        <div className="flex flex-col justify-between  lg:flex-row lg:items-center">
                          <p className="font-semibold text-red-100">Total</p>
                          <span className="text-red-100">
                            {(
                              (offeringData.find(({ term }) => term === 36)
                                ?.totalOfferingExVatDiscounted || 0) +
                              (offeringData.find(({ term }) => term === 36)
                                ?.vatAmount || 0)
                            ).toLocaleString("en-ZA", {
                              style: "currency",
                              currency: "ZAR",
                            })}
                          </span>
                        </div>
                      </div>
                    </div>
                  </aside>
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <div className="relative mb-6 grid grid-cols-12 gap-4">
                <div className="col-span-12 rounded-2xl bg-white p-6 shadow md:p-8 lg:col-span-8 lg:p-10 xl:col-span-9 xl:p-12">
                  <div className="mb-2 text-center sm:text-end"></div>
                  {productData.map(({ siteName, description }) => {
                    return (
                      <div key={siteName} className="my-6 grid">
                        <h1 className="mb-4 text-xl font-semibold text-red-100">
                          {siteName}
                        </h1>

                        <h2 className="mb-3 text-base font-semibold">
                          Description
                        </h2>
                        <hr className="mb-1 border-grey-100" />
                        {description
                          .filter((item) => item.term === 48)
                          .map(({ product }) => {
                            return (
                              <p
                                key={product}
                                className="border-0 border-b border-b-grey-100 py-3"
                              >
                                {product}
                              </p>
                            );
                          })}
                      </div>
                    );
                  })}

                  <InputField
                    inputLabel="Notes"
                    multiline
                    aria-label="notes text area"
                    placeholder="Enter your notes here..."
                    rows={4}
                  />
                </div>
                {offeringData.find(({ term }) => term === 48) && (
                  <aside className="col-start-1 col-end-13 lg:col-start-9 xl:col-start-10">
                    <div className="flex flex-col flex-wrap gap-4 lg:sticky lg:top-[110px] lg:px-4">
                      <div className="flex w-full flex-col items-center gap-4 rounded-2xl bg-white p-10 shadow-xl">
                        <span className="bg-gradient-to-b from-light-green-gradient-start to-light-green-gradient-end bg-clip-text text-5xl font-bold text-transparent">
                          {`${
                            offeringData.find(({ term }) => term === 48)
                              ?.efficiency
                          }%`}
                        </span>
                        <h3 className="text-lg font-semibold">
                          A&R Efficiency
                        </h3>
                      </div>
                      <div className="flex w-full flex-col rounded-2xl bg-white p-8 shadow-xl">
                        <div className="mb-6 flex flex-col justify-between  lg:flex-row lg:items-center ">
                          <p className="font-semibold">Discount</p>
                          <span> R 0.00</span>
                        </div>
                        <div className="mb-6 flex flex-col justify-between  lg:flex-row lg:items-center ">
                          <p className="font-semibold">Sub-Total</p>
                          <span>
                            {offeringData
                              .find(({ term }) => term === 48)
                              ?.totalOfferingExVatDiscounted.toLocaleString(
                                "en-ZA",
                                {
                                  style: "currency",
                                  currency: "ZAR",
                                }
                              )}
                          </span>
                        </div>
                        <div className="mb-6 flex flex-col justify-between  lg:flex-row lg:items-center ">
                          <p className="font-semibold">Vat</p>
                          <span>
                            {offeringData
                              .find(({ term }) => term === 48)
                              ?.vatAmount.toLocaleString("en-ZA", {
                                style: "currency",
                                currency: "ZAR",
                              })}
                          </span>
                        </div>
                        <div className="flex flex-col justify-between  lg:flex-row lg:items-center">
                          <p className="font-semibold text-red-100">Total</p>
                          <span className="text-red-100">
                            {(
                              (offeringData.find(({ term }) => term === 48)
                                ?.totalOfferingExVatDiscounted || 0) +
                              (offeringData.find(({ term }) => term === 48)
                                ?.vatAmount || 0)
                            ).toLocaleString("en-ZA", {
                              style: "currency",
                              currency: "ZAR",
                            })}
                          </span>
                        </div>
                      </div>
                    </div>
                  </aside>
                )}
              </div>
            </TabPanel>

            <div className="relative mb-8 flex flex-col-reverse justify-end gap-4 sm:flex-row lg:mb-12">
              <Button
                variant="text"
                color="secondary"
                className="w-full md:w-fit"
                LinkComponent={Link}
                to={-1}
                disableFocusRipple
              >
                Cancel
              </Button>
              <div className="relative">
                <Button
                  type="submit"
                  variant="contained"
                  disableFocusRipple
                  className="w-full md:w-fit"
                  onClick={handleSubmitForApproval}
                  disabled={submitIsLoading}
                >
                  Submit for approval
                </Button>

                {submitIsLoading && (
                  <div className="absolute top-1/2 left-1/2 mt-[3px] -translate-x-1/2 -translate-y-1/2">
                    <CircularProgress
                      size={30}
                      thickness={4}
                      className="text-grey-700"
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </section>

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}
