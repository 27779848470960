import axios from "axios";

const API_URL = "/api/opportunities/";

// Opportunity
const getAllOpportunities = () => {
  return axios.get(API_URL);
};
const createOpportunity = (opportunity) => {
  return axios.post(API_URL, opportunity);
};
const getOpportunityByID = (id) => {
  return axios.get(API_URL + id);
};
const updateOpportunity = (id, opportunity) => {
  return axios.put(API_URL + id, opportunity);
};
const updateOpportunityStep = (id, step) => {
  return axios.put(API_URL + id + "/step", { step });
};

// Opportunity Line Items
const createOpportunityLineItem = (opportunityLineItem) => {
  return axios.post(API_URL + "line-items", opportunityLineItem);
};
const updateOpportunityLineItem = (id, opportunityLineItem) => {
  return axios.put(API_URL + "line-items/" + id, opportunityLineItem);
};
const deleteOpportunityLineItem = (id) => {
  return axios.delete(API_URL + "line-items/" + id);
};

// Estimation
const generateEstimation = (requirements) => {
  return axios.post(API_URL + "generate-estimation", requirements);
};
const getOpportunityEstimation = (opportunityID) => {
  return axios.get(API_URL + "estimations/" + opportunityID);
};
const submitForApproval = (opportunityID) => {
  return axios.post(API_URL + opportunityID + "/submit-for-approval");
};

// Quote
const getOpportunityQuote = (opportunityID) => {
  return axios.get(API_URL + opportunityID + "/quote");
};

const OpportunityService = {
  // Opportunity
  getAllOpportunities,
  createOpportunity,
  getOpportunityByID,
  updateOpportunity,
  updateOpportunityStep,
  // Opportunity Line Items
  createOpportunityLineItem,
  updateOpportunityLineItem,
  deleteOpportunityLineItem,
  // Estimation
  generateEstimation,
  getOpportunityEstimation,
  submitForApproval,
  // Quote
  getOpportunityQuote,
};

export default OpportunityService;
