// import components
import InlineSVG from "../inline-svg/inline-svg-component";

// import icons
import errorIconOutline from "../../assets/icons/error-circle-no-fill-icon.svg";
import infoIcon from "../../assets/icons/info-icon.svg";
import successIcon from "../../assets/icons/tick-circle-outline-icon.svg";

// configure severity
const severityConfig = {
  error: {
    class: "bg-red-20 border-red-100 text-red-900",
    icon: errorIconOutline,
    iconColor: "fill-red-300",
  },
  success: {
    class: "bg-green-40 border-green-100 text-green-800",
    icon: successIcon,
    iconColor: "fill-green-800",
  },
  warning: {
    class: "bg-yellow-40 border-yellow-100 text-yellow-800",
    icon: errorIconOutline,
    iconColor: "fill-yellow-800",
  },
  default: {
    class: "bg-gray-500 border-gray-500 text-gray-100",
    icon: infoIcon,
    iconColor: "fill-gray-100",
  },
};

export default function StatusChip({
  severity = "default",
  label,
  hasIcon = true,
}) {
  const { class: assignClass, icon, iconColor } = severityConfig[severity];

  return (
    <div
      className={`flex w-fit items-center gap-1 whitespace-nowrap rounded-full border ${assignClass} py-2 pl-3 pr-4`}
    >
      {hasIcon && (
        <InlineSVG
          src={icon}
          className={iconColor}
          width={14}
          height={14}
          ariaHidden
        />
      )}
      <p className="text-xs font-normal">{label}</p>
    </div>
  );
}
