import { Outlet } from "react-router-dom";
import Footer from "../components/footer/footer-component";
import Navbar from "../components/navbar/navbar-component";
import SystemNotification from "../components/system-notification/system-notification-component";

export default function TwelveColGrid() {
  return (
    <div className="flex min-h-screen flex-col bg-screen-grey">
      <Navbar />
      <main className="grid grid-cols-12 gap-x-6">
        <Outlet />
        <SystemNotification />
      </main>
      <Footer />
    </div>
  );
}
