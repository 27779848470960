import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function BackButton(props) {
  const navigate = useNavigate();

  const goBack = (url = -1) => {
    navigate(url);
  };

  return (
    <Button
      variant="text"
      onClick={() => {
        goBack(props.to);
      }}
      disableRipple
      className="inline-flex items-center gap-2 fill-black p-1 text-base text-black transition duration-200 hover:fill-red-100 hover:text-red-100 hover:no-underline"
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 16 14"
        width={16}
        height={14}
      >
        <path d="M7.06 1.242A.727.727 0 1 0 6.03.213L.213 6.031a.727.727 0 0 0 0 1.029l5.818 5.818a.727.727 0 0 0 1.029-1.029L2.483 7.273h12.79a.727.727 0 1 0 0-1.455H2.483L7.06 1.242Z" />
      </svg>
      Back
    </Button>
  );
}
