import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";

// Redux Imports
import { useDispatch, useSelector } from "react-redux";
import { getSiteData, updateSiteData } from "../../slices/feasibility-check";

// MUI Imports
import {
  Button,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Slide,
  Snackbar,
  Switch,
} from "@mui/material";

// Custom Component Imports
import BackButton from "../../components/back-button/back-button-component";
import LeafletMap from "../../components/leaflet-map/leaflet-map-component";
import InputField from "../../components/input/input-component";
import InlineSVG from "../../components/inline-svg/inline-svg-component";
import PlusMinusInputField from "../../components/plus-minus-input-field/plus-minus-input-field-component";
import CustomCheckbox from "../../components/checkbox/checkbox-component";

// Icon Imports
import PinIcon from "../../assets/icons/pin-icon.svg";
import tickIcon from "../../assets/icons/tick.svg";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

// Helper Imports
import { snackSettings } from "../../helpers/helpers";

// Leaflet Imports
import { OpenStreetMapProvider } from "leaflet-geosearch";

// Mock data
import {
  bandwidthRequiredMockData,
  siteOptionsMockData,
  slaMockData,
} from "../../data/mock-data";
//

export default function FeasibilityCheck() {
  // ID from Params
  const { id, opportunityID, product } = useParams();

  // Navigate
  const navigate = useNavigate();

  // Site data from Redux
  const sitesData = useSelector(getSiteData);
  const siteData = sitesData.find((site) => site.id == id);

  useEffect(() => {
    if (!siteData) {
      if (opportunityID) {
        navigate(`/new-opportunity/${opportunityID}/requirements/${product}`);
      } else {
        navigate("/home");
      }
    }
  }, [navigate, siteData]);

  // Map Position
  const defaultMapPosition = siteData?.map_position || [-25.97004, 28.12334];
  const [mapPosition, setMapPosition] = useState(defaultMapPosition);

  // Search Results
  const [searchResults, setSearchResults] = useState([]);

  // Map Provider used in address search
  const mapProvider = new OpenStreetMapProvider();

  // Redux
  const dispatch = useDispatch();

  // React Hook Form
  const {
    register,
    handleSubmit,
    trigger,
    watch,
    setValue,
    formState: { errors },
    getValues,
  } = useForm();

  // Form watch data
  const newSite = watch("new_site", siteData?.new_site);
  const address = watch("address", siteData?.address || "");
  const siteName = watch("site_name", siteData?.site_name || "");
  const bandwidthRequired = watch(
    "bandwidth_required",
    siteData?.bandwidth_required || ""
  );
  const sla = watch("sla", siteData?.hub_site ? "hub" : siteData?.sla || "");
  const requiresWiFi = watch("requires_wifi", siteData?.requires_wifi || false);
  const numberOfUsers = watch(
    "number_of_users",
    siteData?.number_of_users || 0
  );

  // Site Address when selected from sites dropdown
  const [siteAddress, setSiteAddress] = useState("");

  // Show marker on map
  const [showMarker, setShowMarker] = useState(false);

  // On Address Search
  const onAddressSearch = async (value) => {
    setValue("address", value);

    if (value === "") {
      setMapPosition(defaultMapPosition);
      setShowMarker(false);
      setSearchResults([]);
    } else {
      const results = await mapProvider.search({ query: value });

      // Add a little delay to show the results
      setTimeout(() => {
        if (results.length > 0) {
          setSearchResults(results);
        } else {
          setSearchResults([]);
        }
      }, 200);
    }
  };

  // select address from search results
  const onSelectAddress = (result) => {
    setValue("address", result.label);
    setMapPosition([result.y, result.x]);
    setShowMarker(true);
    setSearchResults([]);
  };

  // On any form element change
  const checkValidationAndSaveData = (element) => {
    trigger(element);
    saveSiteData();
  };

  // Set Number of users
  const setNumberOfUsers = (value) => {
    setValue("number_of_users", value);

    // Add delay to save information when numbers change
    setTimeout(() => {
      checkValidationAndSaveData("number_of_users");
    }, 2000);
  };

  // Availability Checked on address
  const [availabilityChecked, setAvailabilityChecked] = useState(
    siteData?.passed || false
  );

  // On "Check Availability" Click
  const onCheckAvailabilityClick = async (e) => {
    e.preventDefault();

    const isValidForm = await trigger();

    if (isValidForm) {
      setAvailabilityChecked(true);
    }
  };

  // On Form Submit
  const onSubmit = async (data) => {
    const isValidForm = await trigger();

    if (isValidForm) {
      // Mock dispatch to update the site as "passed" in feasibility check
      // -Pass feasibility only for the following coordinates
      const slaPassed =
        parseInt(bandwidthRequired) <=
        (slaMockData.find((v) => v.value === sla)?.maxBandwidth || 0);

      dispatch(
        updateSiteData({
          id: id,
          data: {
            ...data,
            map_position: mapPosition,
            passed: slaPassed,
          },
        })
      );

      saveSiteData();
      navigate(`/new-opportunity/${opportunityID}/requirements/${product}`);
    }
  };

  // Save Site Data
  const saveSiteData = () => {
    try {
      const formData = getValues();

      const updatedSiteData = {
        ...formData,
        map_position: mapPosition,
      };

      dispatch(updateSiteData({ id: id, data: updatedSiteData }));

      handleGenericSnackOpen("success", "Feasibility information saved.");
    } catch (error) {
      console.error("Error occurred while saving site data:", error);

      handleGenericSnackOpen(
        "error",
        "Failed to save feasibility information."
      );
    }
  };

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  return (
    <>
      <section
        aria-label="Map for Feasibility Check"
        className="relative col-start-1 col-end-13 row-start-1 h-[40vh] md:h-auto md:min-h-[calc(100vh-137px)]"
      >
        <div className="relative top-0 z-0 h-full w-full md:absolute">
          <LeafletMap marker={showMarker} position={mapPosition} zoom={20} />
        </div>
      </section>

      <section
        aria-label="Feasibility Check"
        className="col-start-1 col-end-13 row-start-2 md:row-start-1"
      >
        <div className="grid grid-cols-12 md:container">
          <div className="relative z-10 col-start-1 col-end-13 m-0 bg-white p-4 md:col-end-10 md:m-10 md:rounded md:p-8 md:shadow lg:col-end-7 xl:col-end-6 2xl:col-end-5 2xl:m-12">
            <div className="mb-4">
              <BackButton
                to={`/new-opportunity/${opportunityID}/requirements/${product}`}
              ></BackButton>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <FormControlLabel
                className="ml-0 mb-4 self-start"
                control={
                  <Switch
                    color="primary"
                    checked={newSite}
                    {...register("new_site")}
                  />
                }
                label="New site"
                labelPlacement="start"
              /> */}

              {true && (
                <div className="relative mb-4">
                  <InputField
                    placeholder="Enter address..."
                    defaultValue={address}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <InlineSVG
                            src={PinIcon}
                            width={24}
                            height={24}
                            ariaHidden
                            className="fill-black"
                          />
                        </InputAdornment>
                      ),

                      ...register("address", {
                        required: "Address is required",
                      }),
                      onBlur: () => checkValidationAndSaveData("address"),
                      onChange: (e) => onAddressSearch(e.target.value),
                    }}
                    error={Boolean(errors.address)}
                    helperText={errors.address?.message}
                  />

                  {/* results */}
                  {searchResults.length > 0 && (
                    <div className="absolute top-full left-0 z-10 mt-1 h-fit w-full overflow-auto rounded-lg bg-white px-4 shadow">
                      <ul className="flex flex-col gap-4 py-4">
                        {searchResults?.map((result) => (
                          <li
                            className="cursor-pointer truncate text-xs text-black hover:text-red-100"
                            key={result.raw.place_id}
                            onClick={() => onSelectAddress(result)}
                          >
                            {result.label}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}

              <div className="mb-4">
                <>
                  {false ? (
                    <>
                      <InputField
                        select
                        inputLabel="Site"
                        value={siteName}
                        SelectProps={{
                          IconComponent: (props) => (
                            <ArrowDropDownRoundedIcon {...props} />
                          ),
                          displayEmpty: true,
                        }}
                        InputProps={{
                          ...register("site_name", {
                            required: "Site selection is required",
                          }),
                          onBlur: () => checkValidationAndSaveData("site_name"),
                          onChange: (e) => {
                            setValue("site_name", e.target.value);
                            setSiteAddress(
                              siteOptionsMockData.find(
                                (option) => option.value === e.target.value
                              ).address
                            );
                          },
                        }}
                        error={Boolean(errors.site_name)}
                        helperText={errors.site_name?.message}
                      >
                        <MenuItem disabled value="">
                          - Select -
                        </MenuItem>
                        {siteOptionsMockData.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </InputField>

                      {siteAddress !== "" && (
                        <p className="mt-2">
                          <span className="font-bold">Address</span>{" "}
                          {siteAddress}
                        </p>
                      )}
                    </>
                  ) : (
                    <InputField
                      inputLabel="Site name"
                      placeholder="Enter site name..."
                      defaultValue={siteName}
                      InputProps={{
                        ...register("site_name", {
                          required: "Site name is required",
                        }),
                        onBlur: () => checkValidationAndSaveData("site_name"),
                      }}
                      error={Boolean(errors.site_name)}
                      helperText={errors.site_name?.message}
                    />
                  )}
                </>
              </div>

              <div className="mb-4">
                <InputField
                  select
                  inputLabel="Bandwidth Required"
                  value={bandwidthRequired}
                  SelectProps={{
                    IconComponent: (props) => (
                      <ArrowDropDownRoundedIcon {...props} />
                    ),
                    displayEmpty: true,
                  }}
                  InputProps={{
                    ...register("bandwidth_required", {
                      required: "Bandwidth selection is required",
                    }),
                    onBlur: () =>
                      checkValidationAndSaveData("bandwidth_required"),
                  }}
                  error={Boolean(errors.bandwidth_required)}
                  helperText={errors.bandwidth_required?.message}
                >
                  <MenuItem disabled value="">
                    - Select -
                  </MenuItem>
                  {bandwidthRequiredMockData.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </InputField>
              </div>

              <div className="mb-4">
                <InputField
                  select
                  inputLabel="SLA"
                  value={sla}
                  SelectProps={{
                    IconComponent: (props) => (
                      <ArrowDropDownRoundedIcon {...props} />
                    ),
                    displayEmpty: true,
                  }}
                  InputProps={{
                    ...register("sla"),
                    onBlur: () => checkValidationAndSaveData("sla"),
                  }}
                >
                  <MenuItem disabled value="">
                    - Select -
                  </MenuItem>
                  {slaMockData
                    .filter((v) => {
                      if (siteData?.hub_site) {
                        return v.value === "hub";
                      }
                      return v.value !== "hub";
                    })
                    .map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </InputField>
              </div>

              <div className="mb-4">
                <PlusMinusInputField
                  label="Number of Users"
                  value={numberOfUsers}
                  setValue={setNumberOfUsers}
                  InputProps={{ ...register("number_of_users") }}
                />
              </div>

              <div className="mb-4">
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={requiresWiFi}
                      inputProps={{ ...register("requires_wifi") }}
                    />
                  }
                  label="Site requires WiFi capability"
                />
              </div>

              {availabilityChecked && (
                <>
                  <div className="mb-4">
                    <h3 className="text-lg font-semibold text-red-100">
                      The following bandwidth is available, per SLA type, at
                      this location:
                    </h3>
                  </div>

                  <div className="my-4">
                    <div className="flex items-center justify-between gap-4">
                      <p className="font-semibold">Standard</p>
                      <p className="font-semibold">1000 Mbps</p>
                    </div>
                    <div className="flex items-center justify-between gap-4">
                      <p className="font-semibold">Standard+</p>
                      <p className="font-semibold">750 Mbps</p>
                    </div>
                    <div className="flex items-center justify-between gap-4">
                      <p className="font-semibold">Enhanced</p>
                      <p className="font-semibold">500 Mbps</p>
                    </div>
                    <div className="flex items-center justify-between gap-4">
                      <p className="font-semibold">Premium</p>
                      <p className="font-semibold">0 Mbps</p>
                    </div>
                    <div className="flex items-center justify-between gap-4">
                      <p className="font-semibold">Hub</p>
                      <p className="font-semibold">2000 Mbps</p>
                    </div>
                  </div>
                </>
              )}

              {availabilityChecked ? (
                <Button
                  type="submit"
                  variant="contained"
                  disableFocusRipple
                  className="mt-3 w-full"
                >
                  Continue
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disableFocusRipple
                  className="mt-3 w-full"
                  onClick={onCheckAvailabilityClick}
                >
                  Check availability
                </Button>
              )}
            </form>
          </div>
        </div>
      </section>

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}
