// Dashboard Opportunity Table Mock Data
export const opportunityMockData = [
  {
    id: 1,
    refID: "OPP-0001234567890",
    customer: "Customer name",
    product: "Product name",
    opportunity_size: 0,
    created: "01/01/2024",
    status: "Pending",
  },
  {
    id: 2,
    refID: "OPP-0001234567891",
    customer: "Customer name",
    product: "Product name",
    opportunity_size: 0,
    created: "01/01/2024",
    status: "Accepted",
  },
  {
    id: 3,
    refID: "OPP-0001234567892",
    customer: "Customer name",
    product: "Product name",
    opportunity_size: 0,
    created: "01/01/2024",
    status: "Declined",
  },
];

// Filter Options
export const filterOptionsMockData = [
  "Option 1",
  "Option 2",
  "Option 3",
  "Option 4",
  "Option 5",
];

// New Opportunity - Step 1 - Custom Dropdown
export const customDropdownMockData = [
  {
    value: "customer-name-1",
    label: "Customer Name 1",
  },
  {
    value: "customer-name-2",
    label: "Customer Name 2",
  },
  {
    value: "customer-name-3",
    label: "Customer Name 3",
  },
  {
    value: "customer-name-4",
    label: "Customer Name 4",
  },
];

// New Opportunity - Step 1 - Billing Account Dropdown
export const billingAccountDropdownMockData = [
  {
    value: "billing-account-1",
    label: "Billing Account 1",
  },
  {
    value: "billing-account-2",
    label: "Billing Account 2",
  },
  {
    value: "billing-account-3",
    label: "Billing Account 3",
  },
  {
    value: "billing-account-4",
    label: "Billing Account 4",
  },
];

// Opportunity type dropdown options
export const opportunityTypeOptionsMockData = [
  {
    value: "opportunity-type-1",
    label: "Opportunity Type 1",
  },
  {
    value: "opportunity-type-2",
    label: "Opportunity Type 2",
  },
];

// Currency dropdown options
export const mockCurrencyOptions = [
  {
    value: "usd",
    label: "USD",
  },
  {
    value: "zar",
    label: "ZAR",
  },
  {
    value: "eur",
    label: "EUR",
  },
  {
    value: "gbp",
    label: "GBP",
  },
];

// Entity dropdown options
export const entityOptionsMockData = [
  {
    value: "South-Africa",
    label: "South Africa",
  },
];

// Deal type dropdown options
export const dealTypeOptionsMockData = [
  {
    value: "deal-type-1",
    label: "Deal Type 1",
  },
  {
    value: "deal-type-2",
    label: "Deal Type 2",
  },
];

// Primary contact dropdown options
export const primaryContactOptionsMockData = [
  {
    value: "John Doe",
    label: "John Doe",
  },
  {
    value: "Jane Doe",
    label: "Jane Doe",
  },
];

// Complexity Dropdown options
export const complexityOptionsMockData = [
  {
    value: "complexity-1",
    label: "Complexity 1",
  },
  {
    value: "complexity-2",
    label: "Complexity 2",
  },
];

// Lead Region dropdown options
export const opportunityLeadRegionOptionsMockData = [
  {
    value: "gauteng",
    label: "Gauteng",
  },
  {
    value: "western-cape",
    label: "Western Cape",
  },
];

// Region dropdown options
export const opportunityRegionOptionsMockData = [
  {
    value: "middle-east-africa",
    label: "Middle East & Africa",
  },
  {
    value: "asia-pacific",
    label: "Asia Pacific",
  },
];

// Mock data for feasibility sites
export const feasibilitySitesMockData = [
  {
    id: 1,
    site_name: "Site 1",
    description: [
      {
        product:
          "Customer site x1 - VeloCloud510 Edge Device x 1, Device Payment Terms = 12 Months",
        cost: 1000,
        term: "12 Months",
      },
      {
        product:
          "Customer site x1 - VeloCloud510 Edge Device x 1, Device Payment Terms = 24 Months",
        cost: 1000,
        term: "24 Months",
      },
      {
        product: "10Mbps Standard License, License Payment Terms = 12 Months",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "10Mbps Standard License, License Payment Terms = 24 Months",
        cost: 1000,
        term: "24 Months",
      },
      {
        product: "Management Fee per device x 3, Payment Terms = 12 Months",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "Setup Fee x 3, Installation Fee per device",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "Warranty (RMA) = 12 Months x 3",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "LTE x 1 Router + SIM + 100GB Capped",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "SD-WAN Connect x 2 Once-off fee",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "IP Connect Standard Plus x 1, Payment Terms = 12 Months",
        cost: 1000,
        term: "12 Months",
      },
    ],
  },
  {
    id: 2,
    site_name: "Site 2",
    description: [
      {
        product:
          "Customer site x1 - VeloCloud510 Edge Device x 1, Device Payment Terms = 12 Months",
        cost: 1000,
        term: "12 Months",
      },
      {
        product:
          "Customer site x1 - VeloCloud510 Edge Device x 1, Device Payment Terms = 24 Months",
        cost: 1000,
        term: "24 Months",
      },
      {
        product: "10Mbps Standard License, License Payment Terms = 12 Months",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "10Mbps Standard License, License Payment Terms = 24 Months",
        cost: 1000,
        term: "24 Months",
      },
      {
        product: "Management Fee per device x 3, Payment Terms = 12 Months",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "Setup Fee x 3, Installation Fee per device",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "Warranty (RMA) = 12 Months x 3",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "LTE x 1 Router + SIM + 100GB Capped",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "SD-WAN Connect x 2 Once-off fee",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "IP Connect Standard Plus x 1, Payment Terms = 12 Months",
        cost: 1000,
        term: "12 Months",
      },
    ],
  },
  {
    id: 3,
    site_name: "Site 3",
    description: [
      {
        product:
          "Customer site x1 - VeloCloud510 Edge Device x 1, Device Payment Terms = 12 Months",
        cost: 1000,
        term: "12 Months",
      },
      {
        product:
          "Customer site x1 - VeloCloud510 Edge Device x 1, Device Payment Terms = 24 Months",
        cost: 1000,
        term: "24 Months",
      },
      {
        product: "10Mbps Standard License, License Payment Terms = 12 Months",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "10Mbps Standard License, License Payment Terms = 24 Months",
        cost: 1000,
        term: "24 Months",
      },
      {
        product: "Management Fee per device x 3, Payment Terms = 12 Months",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "Setup Fee x 3, Installation Fee per device",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "Warranty (RMA) = 12 Months x 3",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "LTE x 1 Router + SIM + 100GB Capped",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "SD-WAN Connect x 2 Once-off fee",
        cost: 1000,
        term: "12 Months",
      },
      {
        product: "IP Connect Standard Plus x 1, Payment Terms = 12 Months",
        cost: 1000,
        term: "12 Months",
      },
    ],
  },
];

// Mock data for Customer View page
export const customerViewMockData = [
  {
    id: 1,
    product: "VeloCloud SD-WAN 10Mbps",
    recurring: 2200,
    non_recurring: 1000,
    term: "12 Months",
  },
  {
    id: 2,
    product: " VeloCloud 510 Edge Device x 1",
    cost: 2300,
    recurring: 2250,
    non_recurring: 1000,
    term: "24 Months",
  },
];

// Mock data for Products
export const productsMockData = [
  {
    id: 1,
    product_name: "Business connect",
    date_created: "",
  },
  {
    id: 2,
    product_name: "Dedicated Internet Access",
  },
  {
    id: 3,
    product_name: "MLPS VPN",
  },
  {
    id: 4,
    product_name: "IP Connect Satellite",
  },
  {
    id: 5,
    product_name: "Enterprise Wifi",
  },
  {
    id: 6,
    product_name: "SD-WAN",
  },
];

// Product sorting options
export const productSortOptions = [
  {
    value: "Option1",
    label: "Option 1",
  },
  {
    value: "Option2",
    label: "Option 2",
  },
];

// Product filter options
export const productFiltersOptions = [
  {
    label: "Option 1",
    value: "option1",
  },
  {
    label: "Option 2",
    value: "option2",
  },
  {
    label: "Option 3",
    value: "option3",
  },
  {
    label: "Option 4",
    value: "option4",
  },
  {
    label: "Option 5",
    value: "option5",
  },
];

// Bandwidth Required Dropdown Options
export const bandwidthRequiredMockData = [
  {
    value: 10,
    label: "10 Mbps",
  },
  {
    value: 20,
    label: "20 Mbps",
  },
  {
    value: 25,
    label: "25 Mbps",
  },
  {
    value: 30,
    label: "30 Mbps",
  },
  {
    value: 50,
    label: "50 Mbps",
  },
  {
    value: 100,
    label: "100 Mbps",
  },
  {
    value: 200,
    label: "200 Mbps",
  },
  {
    value: 300,
    label: "300 Mbps",
  },
  {
    value: 500,
    label: "500 Mbps",
  },
  {
    value: 750,
    label: "750 Mbps",
  },
  {
    value: 1000,
    label: "1000 Mbps",
  },
  {
    value: 1500,
    label: "1500 Mbps",
  },
  {
    value: 2000,
    label: "2000 Mbps",
  },
];

// Select Site Mock Data
export const siteOptionsMockData = [
  {
    value: "Site 1",
    label: "Site 1",
    address: "123 Main Street, Cityville",
  },
  {
    value: "Site 2",
    label: "Site 2",
    address: "123 Main Street, Cityville",
  },
  {
    value: "Site 3",
    label: "Site 3",
    address: "123 Main Street, Cityville",
  },
];

// SLA Dropdown Options
export const slaMockData = [
  {
    value: "low",
    label: "Standard (98.33%)",
    maxBandwidth: 1000,
  },
  {
    value: "low_plus",
    label: "Standard+ (98.88%)",
    maxBandwidth: 750,
  },
  {
    value: "medium",
    label: "Enhanced (99.16%)",
    maxBandwidth: 500,
  },
  {
    value: "high",
    label: "Premium (99.72%)",
    maxBandwidth: 0,
  },
  {
    value: "hub",
    label: "Hub Site (99.72%)",
    maxBandwidth: 2000,
  },
];
