export default function Footer() {
  const { hostname } = window.location;
  const footerText = hostname === "condorwebtests.link" ? "" : "Vodacom. ";
  return (
    <footer className="mt-auto w-full bg-[#202020] py-3 px-4 text-center">
      <p className="m-0 text-base text-white">
        Terms &amp; Conditions &copy; {new Date().getFullYear()} - {footerText}
        All rights reserved.
      </p>
    </footer>
  );
}
