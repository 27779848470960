import { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer, ZoomControl } from "react-leaflet";
import MarkerIcon from "../../assets/icons/map-location-pin-icon.svg";
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const LeafletMap = ({
  position = [-25.97004, 28.12334],
  zoom = 13,
  scrollWheelZoom = false,
  controls = true,
  marker = true
}) => {

  // Custom marker icon
  const customMarkerIcon = new L.Icon({
    iconUrl: MarkerIcon,
    iconSize: [56, 56], // Size of the icon
    iconAnchor: [28, 56], // Point of the icon which will correspond to marker's location
  });

  // Key to force re-render
  const [key, setKey] = useState(0);

  // Increment key to force re-render
  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [position])

  return (
    <MapContainer center={position} zoom={zoom} scrollWheelZoom={scrollWheelZoom} zoomControl={false} key={key}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' 
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {marker && <Marker position={position} icon={customMarkerIcon}></Marker>}
      {controls && <ZoomControl position="bottomright" />}
    </MapContainer>
  );
};

export default LeafletMap;
