import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";

const CustomIcon = styled("span")(() => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  backgroundColor: "#D8DBE0",
  ".Mui-focusVisible &": {
    outline: "1px auto rgba(216,219,224,.8)",
    outlineOffset: 2,
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "#C9CCD4",
  },
}));

const CustomCheckedIcon = styled(CustomIcon)({
  backgroundColor: "#E60000",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3E%3Cpath fill='%23fff' d='M3.571 8a1 1 0 0 1-.693-.279L.307 5.251a1 1 0 1 1 1.386-1.443l1.828 1.757L8.258.329a1 1 0 0 1 1.484 1.342l-5.429 6a1 1 0 0 1-.707.328Z'/%3E%3C/svg%3E\")",
    backgroundSize: "10px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#E60000",
  },
  ".Mui-focusVisible &": {
    outline: "1px auto rgba(230,0,0,.25)",
    outlineOffset: 2,
  },
});

const CustomCheckbox = (props) => {
  return (
    <Checkbox
      {...props}
      color="default"
      disableFocusRipple
      checkedIcon={<CustomCheckedIcon />}
      icon={<CustomIcon />}   
    />
  );
}

export default CustomCheckbox;