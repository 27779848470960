// React Imports
import { IconButton, Menu } from "@mui/material";
import React, { useState } from "react";

// Icon Imports
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";

// Unique ID Import
import { v4 as uuidv4 } from "uuid";

const MoreOptions = ({
  children, 
  orientation = 'horizontal',
  disablePortal = true,
  ...props
}) => {
  // Unique ID
  const uid = uuidv4();

  // Anchor position for more options
  const [anchorEl, setAnchorEl] = useState(null);
  const moreOptionsOpen = Boolean(anchorEl);

  // Handle options open event
  const handleOptionsOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // Handle options close event
  const handleOptionsClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id={`${uid}-more-menu-trigger`}
        className="text-red-100"
        aria-label="More options"
        aria-controls={moreOptionsOpen ? `${uid}-more-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={moreOptionsOpen ? "true" : undefined}
        onClick={handleOptionsOpen}
      >
        {orientation === 'horizontal' ? <MoreHorizRoundedIcon /> : <MoreVertRoundedIcon />}
      </IconButton>
      <Menu
        id={`${uid}-more-menu`}
        anchorEl={anchorEl}
        open={moreOptionsOpen}
        keepMounted
        disablePortal={disablePortal}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleOptionsClose}
        MenuListProps={{
          "aria-labelledby": `${uid}-more-menu-trigger`,
        }}
      >
        {children}
      </Menu>
    </>
  );
}

export default MoreOptions;