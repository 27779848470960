// React Imports
import { Route, Routes } from "react-router-dom";
// import { useEffect, useCallback } from "react";
// import EventBus from "./common/EventBus";

// // Redux Imports
// import { useSelector, useDispatch } from "react-redux";
// import { logout } from "./slices/auth";

// Layout Imports
import TwelveColGrid from "./layouts/12-col-grid";

// Page Imports
import SignIn from "./pages/sign-in/sign-in-page";
import Home from "./pages/home/home-page";
import FeasibilityCheck from "./pages/feasibility-check/feasibility-check-page";
import Step1Opportunity from "./pages/new-opportunity/step-1-opportunity/step-1-opportunity-page";
import Step2Product from "./pages/new-opportunity/step-2-product/step-2-product-page";
import Step3Requirements from "./pages/new-opportunity/step-3-requirements/step-3-requirements-page";
import Step4Estimate from "./pages/new-opportunity/step-4-estimate/step-4-estimate-page";
import Error404 from "./pages/errors/404/404-page";
import Error401 from "./pages/errors/401/401-page";

// Socket Import
import socketIOClient from "socket.io-client";
import AuthLayout from "./layouts/auth-layout";
//import ForgotPassword from "./pages/forgot-password/forgot-password";
import CustomerView from "./pages/new-opportunity/customer-view/customer-view";
import ProtectedRoute from "./components/auth/protected-route";
import CustomerQuote from "./pages/new-opportunity/customer-quote/customer-quote";

export const socket = socketIOClient(window.location.origin, {
  path: "/api/socket.io",
});

export default function App() {
  // const { user: currentUser } = useSelector((state) => state.auth);
  // const dispatch = useDispatch();

  // const logOut = useCallback(() => {
  //   dispatch(logout());
  // }, [dispatch]);

  // useEffect(() => {
  //   if (currentUser) {
  //     // console.log(currentUser.roles);
  //   }

  //   EventBus.on("logout", () => {
  //     logOut();
  //   });

  //   return () => {
  //     EventBus.remove("logout");
  //   };
  // }, [currentUser, logOut]);

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="/" element={<SignIn />} />
        {/* Error pages */}
        <Route path="/error-404" element={<Error404 />} />
        <Route path="/error-401" element={<Error401 />} />
      </Route>
      <Route
        path="/new-opportunity/:opportunityID/customer-quote"
        element={
          <ProtectedRoute>
            <CustomerQuote />
          </ProtectedRoute>
        }
      />
      <Route element={<TwelveColGrid />}>
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/new-opportunity/:opportunityID/opportunity"
          element={
            <ProtectedRoute>
              <Step1Opportunity />
            </ProtectedRoute>
          }
        />
        <Route
          path="/new-opportunity/opportunity"
          element={
            <ProtectedRoute>
              <Step1Opportunity />
            </ProtectedRoute>
          }
        />
        <Route
          path="/new-opportunity/:opportunityID/customer-view"
          element={
            <ProtectedRoute>
              <CustomerView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/new-opportunity/:opportunityID/product"
          element={
            <ProtectedRoute>
              <Step2Product />
            </ProtectedRoute>
          }
        />
        <Route
          path="/new-opportunity/:opportunityID/requirements/:product"
          element={
            <ProtectedRoute>
              <Step3Requirements />
            </ProtectedRoute>
          }
        />
        <Route
          path="/new-opportunity/:opportunityID/estimate/:product"
          element={
            <ProtectedRoute>
              <Step4Estimate />
            </ProtectedRoute>
          }
        />
        <Route
          path="/:opportunityID/feasibility-check/:product/:id"
          element={
            <ProtectedRoute>
              <FeasibilityCheck />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
}
