import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

// Redux Imports
import { useDispatch, useSelector } from "react-redux";
import {
  addSite,
  deleteSite,
  getSiteData,
  updateSiteData,
  updateCustomerBudget,
  updateDataLoaded,
  getCustomerBudget,
  getDataLoaded,
  getOpportunity,
  updateOpportunity,
} from "../../../slices/feasibility-check";

// MUI Imports
import {
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  FormControlLabel,
  Button,
  Link,
  Alert,
  Dialog,
  DialogTitle,
  useMediaQuery,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
  Snackbar,
  CircularProgress,
} from "@mui/material";

// Helper Imports
import { snackSettings } from "../../../helpers/helpers";

// Custom Component Imports
import BackButton from "../../../components/back-button/back-button-component";
import InputField from "../../../components/input/input-component";
import PlusMinusInputField from "../../../components/plus-minus-input-field/plus-minus-input-field-component";
import CustomCheckbox from "../../../components/checkbox/checkbox-component";
import FeasibilitySiteCard from "../../../components/feasibility-site-card/feasibility-site-card-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";

// Masking Values Imports
import { useIMask } from "react-imask";
import { maskOptions } from "../../../helpers/helpers";

// Steps Import
import { newOpportunitySteps } from "../step-1-opportunity/step-1-opportunity-page";

// Icon Imports
import WarningIcon from "../../../assets/icons/warning-icon.svg";
import OpportunityService from "../../../services/opportunity.service";
import AuthService from "../../../services/auth.service";
import { setMessage } from "../../../slices/message";

export default function Step3Requirements() {
  // Product from Params
  const { opportunityID, product } = useParams();

  // Navigate
  const navigate = useNavigate();
  const dataLoaded = useSelector(getDataLoaded);

  if (!opportunityID) {
    navigate("/home");
  } else {
    if (!dataLoaded) {
      // Update Opportunity Step
      OpportunityService.updateOpportunityStep(
        opportunityID,
        "Requirements"
      ).catch((err) => {
        handleServerError(err);
      });
    }
  }

  // Redux Store
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const _opportunity = useSelector(getOpportunity);
  const [opportunity, setOpportunity] = useState(_opportunity);

  // React Hook Form
  const {
    register,
    handleSubmit,
    trigger,
    watch,
    formState: {},
  } = useForm({
    defaultValues:
      opportunityID && !dataLoaded
        ? async () => {
            await OpportunityService.getOpportunityByID(opportunityID)
              .then((response) => {
                const { customerBudget, opportunityLineItems } = response.data;
                dispatch(updateCustomerBudget(customerBudget));
                dispatch(updateDataLoaded(true));
                dispatch(updateOpportunity(response.data));
                opportunityLineItems?.map((item) => {
                  dispatch(
                    addSite({
                      id: item.opportunityLineItemID,
                      opportunityLineItemID: item.opportunityLineItemID,
                      customerLineItemID: item.customerLineItemID,
                      site_name: item.lineItemDescription,
                      map_position: item.lineItemJSON.mapPosition,
                      passed: item.lineItemJSON.passed,
                      hub_site: item.lineItemJSON.hubSite,
                      new_site: item.lineItemJSON.newSite,
                      address: item.lineItemJSON.address,
                      bandwidth_required: item.lineItemJSON.bandwidthMbps,
                      sla: item.lineItemJSON.slaType,
                      number_of_users: item.lineItemJSON.numberOfUsers,
                      requires_wifi: item.lineItemJSON.requiresWiFi,
                    })
                  );
                });
                setOpportunity(response.data);
                setNumberOfSites(opportunityLineItems?.length || 0);
                return response;
              })
              .catch((error) => {
                handleServerError(error);
              });
            return {};
          }
        : {},
  });

  // Form submit
  const onSubmit = async (data) => {
    const isValid = await trigger();
    setIsLoading(true);

    if (isValid) {
      // Form data to be send to relevant DB/API
      // Validation
      if (numberOfSites > 5) {
        // Show more than 5 sites dialog
        setExtraInfoDialogOpen(true);
        setIsLoading(false);
      } else if (passedSitesData.some((site) => site.passed === false)) {
        // Show some sites failed dialog
        setFeasibilityFailedDialogOpen(true);
        setIsLoading(false);
      } else {
        // Save data
        await saveData();

        // Generate
        await OpportunityService.getOpportunityByID(opportunityID)
          .then((response) => {
            const latestOpportunityData = response.data;
            const { opportunityJSON, opportunityLineItems } =
              latestOpportunityData;
            const { terms } = opportunityJSON;
            const opportunityLineItemRequirements = opportunityLineItems.map(
              (item) => {
                const { opportunityLineItemID, lineItemJSON } = item;
                const { bandwidthMbps, slaType } = lineItemJSON;
                return {
                  bandwidthMbps,
                  slaType,
                  opportunityLineItemID,
                };
              }
            );
            const requirements = {
              template: "SDWAN Template",
              opportunityID,
              terms,
              opportunityLineItemRequirements,
            };
            OpportunityService.generateEstimation(requirements)
              .then((response) => {
                const { message } = response.data;
                // Navigate to the next step with product param
                navigate(
                  `/new-opportunity/${opportunityID}/estimate/${product}`
                );
                dispatch(setMessage(message));
              })
              .catch((error) => {
                setIsLoading(false);
                handleServerError(error);
              });
          })
          .catch((error) => {
            setIsLoading(false);
            handleServerError(error);
          });
      }
    }
  };

  const handleContinue = async () => {
    await saveData();
    navigate(`/home`);
  };

  const saveData = async () => {
    // Save data
    // Step 1: Update Opportunity
    // Update opportunity
    const terms = [];
    if (term12) {
      terms.push(12);
    }
    if (term24) {
      terms.push(24);
    }
    if (term36) {
      terms.push(36);
    }
    if (term48) {
      terms.push(48);
    }
    const updateOpportunity = {
      ...opportunity,
      customerBudget: budgetValue
        .replace("R", "")
        .replace(/\s/g, "")
        .replace(",", ""),
      opportunityJSON: {
        terms: terms,
      },
    };
    await OpportunityService.updateOpportunity(
      opportunityID,
      updateOpportunity
    ).catch((error) => {
      setIsLoading(false);
      handleServerError(error);
    });
    // Step 2: Save Opportunity Line Items
    const opportunityLineItems = siteDataRedux.map((item, index) => {
      const {
        newOpportunityLineItem,
        opportunityLineItemID,
        customerLineItemID,
        site_name,
        bandwidth_required,
        sla,
        hub_site,
        map_position,
        address,
        number_of_users,
        requires_wifi,
        passed,
      } = item;
      return {
        newOpportunityLineItem: newOpportunityLineItem || false,
        opportunityID,
        opportunityLineItemID: opportunityLineItemID || null,
        customerLineItemID: customerLineItemID || null,
        lineItemDescription: site_name,
        ordinality: index,
        lineItemJSON: {
          bandwidthMbps: parseInt(bandwidth_required),
          slaType: hub_site ? "hub" : sla,
          hubSite: hub_site,
          mapPosition: map_position,
          address,
          numberOfUsers: number_of_users,
          requiresWiFi: requires_wifi,
          passed,
        },
      };
    });
    // Step 2: A) Create Opportunity Line Items if not exist
    const newOpportunityLineItems = opportunityLineItems.filter(
      (item) => item.newOpportunityLineItem === true
    );
    await Promise.all(
      newOpportunityLineItems.map((item) =>
        OpportunityService.createOpportunityLineItem(item).catch((error) => {
          setIsLoading(false);
          handleServerError(error);
        })
      )
    );

    // Step 2: B) Update Opportunity Line Items if exist
    const existingOpportunityLineItems = opportunityLineItems.filter(
      (item) => item.newOpportunityLineItem === false
    );
    await Promise.all(
      existingOpportunityLineItems.map((item) =>
        OpportunityService.updateOpportunityLineItem(
          item.opportunityLineItemID,
          item
        ).catch((error) => {
          setIsLoading(false);
          handleServerError(error);
        })
      )
    );
  };

  // Dialog
  const [extraInfoDialogOpen, setExtraInfoDialogOpen] = useState(false);
  const [feasibilityFailedDialogOpen, setFeasibilityFailedDialogOpen] =
    useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  // Handle dialog close event
  const handleDialogClose = () => {
    setExtraInfoDialogOpen(false);
    setFeasibilityFailedDialogOpen(false);
  };

  // Disable Submit Button
  const [disableSubmit, setDisableSubmit] = useState(true);

  // Budget input value
  const customerBudget = useSelector(getCustomerBudget);
  const [budgetValue, setBudgetValue] = useState(customerBudget);

  // Mask budget input field
  const { ref: refBudget } = useIMask(maskOptions, {
    onAccept: (value) => {
      setBudgetValue(value);
    },
  });

  // Handle budget input change
  const handleBudgetChange = (e) => {
    let { value } = e.target;
    trigger("customerBudget");

    // Remove `R` and trim
    value = value.replace("R", "").replace(/\s/g, "").replace(",", "");

    // Do something with value
    dispatch(updateCustomerBudget(value));
  };

  // Site data from Redux
  const siteDataRedux = useSelector(getSiteData);
  console.log("siteDataRedux: ", siteDataRedux);

  // Number sites state
  const [numberOfSites, setNumberOfSites] = useState(siteDataRedux.length);

  // Sites data
  const [siteData, setSiteData] = useState(siteDataRedux);
  const [passedSitesData, setPassedSitesData] = useState([]);

  // Update data as redux store updates
  useEffect(() => {
    setSiteData(siteDataRedux);

    // Set "passedSitesData" from the newly updated siteData
    setPassedSitesData(siteDataRedux.filter((site) => site.passed !== null));

    // Enable the button when none of the sites have outstanding feasibility checks
    if (siteDataRedux.some((site) => site.passed !== null)) {
      setDisableSubmit(false);
    }
  }, [siteDataRedux]);

  // useEffect for adding new sites when number of sites are increased
  useEffect(() => {
    // Calculate the difference between the current number of sites and the length of siteData
    const diff = numberOfSites - siteDataRedux.length;

    if (diff > 0 && numberOfSites < 6) {
      for (let i = 0; i < diff; i++) {
        dispatch(
          addSite({
            id: uuidv4(),
            site_name: "",
            map_position: [-25.97004, 28.12334],
            passed: null,
            hub_site: false,
            new_site: true,
            newOpportunityLineItem: true,
          })
        );
      }
    }
  }, [numberOfSites, siteDataRedux, dispatch]);

  // Remove Site
  const removeSite = (id) => {
    const siteData = siteDataRedux.find((site) => site.id == id);
    if (siteData) {
      const { newOpportunityLineItem } = siteData;
      if (newOpportunityLineItem) {
        // Dispatch action to delete site
        dispatch(deleteSite(id));
        setNumberOfSites(numberOfSites - 1);
      } else {
        OpportunityService.deleteOpportunityLineItem(id)
          .then(() => {
            // Dispatch action to delete site
            dispatch(deleteSite(id));
            setNumberOfSites(numberOfSites - 1);
          })
          .catch((error) => {
            handleServerError(error);
          });
      }
    }
  };

  // Update Site Data
  const updateSite = (id, data) => {
    // Dispatch action to update site data
    dispatch(updateSiteData({ id: id, data: data }));
  };

  // Site Hub Site on Site Card
  const setHubSite = (id) => {
    const siteToUpdate = siteData.find((site) => site.id === id);

    if (siteToUpdate) {
      const updatedSite = { ...siteToUpdate, hub_site: !siteToUpdate.hub_site };
      updateSite(id, updatedSite);
    }
  };

  // Terms
  const term12 = watch("term_12", true);
  const term24 = watch("term_24", false);
  const term36 = watch("term_36", false);
  const term48 = watch("term_48", false);

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 400) {
      // Warning
      handleGenericSnackOpen("warning", errors[0].message);
    } else if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/");
    } else if (status === 403) {
      // Unauthorized
      handleGenericSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleGenericSnackOpen("notFound", errors[0].message);
    } else {
      if (errors) {
        // Unexpected server error
        handleGenericSnackOpen("error", errors[0].message);
      } else {
        handleGenericSnackOpen(
          "error",
          `Unexpected server error. Code: {${error.code}}; Message: {${error.message}}`
        );
      }
    }
  };

  return (
    <>
      <div className="container col-start-1 col-end-13 row-start-1 my-6 mx-auto px-4 lg:col-start-2 lg:col-end-12 lg:my-8 lg:px-0">
        <BackButton
          to={`/new-opportunity/${opportunityID}/product`}
        ></BackButton>
      </div>

      <section
        aria-label="Step 3 - Requirements"
        className="container col-start-1 col-end-13 row-start-2 mx-auto px-4 lg:col-start-2 lg:col-end-12 lg:px-0"
      >
        <div className="my-8 grid grid-cols-10">
          <Stepper
            activeStep={2}
            alternativeLabel
            className="col-start-1 col-end-11 lg:col-start-2 lg:col-end-10"
          >
            {newOpportunitySteps.map((label) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={(props) => (
                    <StepIcon
                      {...props}
                      active={props.active || props.completed}
                      completed={false}
                    />
                  )}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>

        <form
          className="my-6 grid grid-cols-10 gap-4 lg:my-12"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="col-start-1 col-end-11 rounded-2xl bg-white py-4 px-6 shadow md:px-8 lg:px-10 lg:py-6 xl:col-start-3 xl:col-end-9 xl:px-12 xl:py-8">
            <div className="mb-6">
              <InputField
                inputLabel="Customer Budget"
                inputRef={refBudget}
                placeholder="R 0,00"
                value={budgetValue}
                InputProps={{
                  ...register("customerBudget"),
                  onBlur: handleBudgetChange,
                }}
                //error={Boolean(errors.budget_input)}
                //helperText={errors.budget_input?.message}
                helperText="(Optional)"
              />
            </div>

            <h2 className="mb-3 text-lg font-bold text-red-100">Sites</h2>
            <hr className="mb-4 border-grey-100" />

            <div className="mb-4">
              <PlusMinusInputField
                showMinus={numberOfSites > 5}
                label="No. of Customer Sites"
                value={numberOfSites}
                setValue={setNumberOfSites}
                readOnly
              />
            </div>

            <div className="my-6 grid grid-cols-1 gap-4 lg:grid-cols-2">
              {numberOfSites < 6 ? (
                <>
                  {siteData.map((site, index) => (
                    <div
                      className={`col-span-1 lg:col-span-${
                        index === siteData.length - 1 &&
                        siteData.length % 2 !== 0
                          ? "2"
                          : "1"
                      }`}
                      key={site.id}
                    >
                      <FeasibilitySiteCard
                        opportunityID={opportunityID}
                        product={product}
                        id={site.id}
                        siteName={
                          site.site_name === ""
                            ? `Site ${index + 1}`
                            : site.site_name
                        }
                        mapPosition={site.map_position}
                        passed={site.passed}
                        hubSite={site.hub_site}
                        setHubSite={() => setHubSite(site.id)}
                        deleteSite={removeSite}
                      />
                    </div>
                  ))}
                  {siteData.some((site) => site.passed === false) && (
                    <Alert
                      className="col-span-full items-center gap-1 rounded-lg"
                      severity="warning"
                      iconMapping={{
                        warning: (
                          <InlineSVG
                            src={WarningIcon}
                            width={24}
                            height={24}
                            ariaHidden
                          />
                        ),
                      }}
                    >
                      <p>
                        One or more of the selected customer sites did not pass
                        the feasibility check. This estimate will be subject to
                        a feasibility investigation by the Solutions
                        Architecture team.
                      </p>
                    </Alert>
                  )}
                </>
              ) : (
                <>
                  {siteData.some((site) => site.passed !== null) &&
                    passedSitesData.map((site, index) => (
                      <div
                        className={`col-span-1 lg:col-span-${
                          index === passedSitesData.length - 1 &&
                          passedSitesData.length % 2 !== 0
                            ? "2"
                            : "1"
                        }`}
                        key={site.id}
                      >
                        <FeasibilitySiteCard
                          opportunityID={opportunityID}
                          product={product}
                          id={site.id}
                          siteName={
                            site.site_name === ""
                              ? `Site ${index + 1}`
                              : site.site_name
                          }
                          mapPosition={site.map_position}
                          passed={site.passed}
                          hubSite={site.hub_site}
                          setHubSite={() => setHubSite(site.id)}
                          deleteSite={removeSite}
                        />
                      </div>
                    ))}

                  {passedSitesData.some((site) => site.passed === false) ? (
                    <Alert
                      className="col-span-full items-center gap-1 rounded-lg"
                      severity="warning"
                      iconMapping={{
                        warning: (
                          <InlineSVG
                            src={WarningIcon}
                            width={24}
                            height={24}
                            ariaHidden
                          />
                        ),
                      }}
                    >
                      <p>
                        Due to the number of sites you've selected and that one
                        or more of them did not pass the feasibility check, this
                        estimate will be subject to a feasibility investigation
                        by the Solutions Architecture team.
                      </p>
                    </Alert>
                  ) : (
                    <Alert
                      className="col-span-full items-center gap-1 rounded-lg"
                      severity="warning"
                      iconMapping={{
                        warning: (
                          <InlineSVG
                            src={WarningIcon}
                            width={24}
                            height={24}
                            ariaHidden
                          />
                        ),
                      }}
                    >
                      <p>
                        Based on the number of sites you've selected, this
                        estimate will be subject to a feasibility investigation
                        by the Solutions Architecture team.
                      </p>
                    </Alert>
                  )}
                </>
              )}
            </div>

            <h2 className="mb-3 text-lg font-bold text-red-100">Terms</h2>
            <hr className="mb-4 border-grey-100" />

            <div className="grid grid-cols-2 gap-4 px-1 md:grid-cols-4">
              <div className="col-span-1">
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={term12}
                      inputProps={{ ...register("term_12") }}
                    />
                  }
                  label={"12 Months"}
                />
              </div>
              <div className="col-span-1">
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={term24}
                      inputProps={{ ...register("term_24") }}
                    />
                  }
                  label="24 Months"
                />
              </div>
              <div className="col-span-1">
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={term36}
                      inputProps={{ ...register("term_36") }}
                    />
                  }
                  label="36 Months"
                />
              </div>
              <div className="col-span-1">
                <FormControlLabel
                  control={
                    <CustomCheckbox
                      checked={term48}
                      inputProps={{ ...register("term_48") }}
                    />
                  }
                  label="48 Months"
                />
              </div>
            </div>
          </div>

          <div className="col-span-10 row-start-3 sm:col-span-5 sm:col-start-1 sm:row-start-2 md:col-span-3 md:col-start-4 lg:col-span-2 lg:col-start-6 xl:col-span-1 xl:col-start-7">
            <Button
              variant="text"
              color="secondary"
              className="w-full"
              LinkComponent={Link}
              to={-1}
              disableFocusRipple
            >
              Cancel
            </Button>
          </div>
          <div className="relative col-span-10 row-start-2 mt-4 sm:col-span-5 sm:col-start-6 sm:mt-0 md:col-span-3 md:col-start-7 lg:col-span-2 lg:col-start-8 xl:col-span-1 xl:col-start-8">
            <Button
              type="submit"
              variant="contained"
              disableFocusRipple
              className="w-full"
              disabled={disableSubmit || isLoading}
            >
              Submit
            </Button>

            {isLoading && (
              <div className="absolute top-1/2 left-1/2 mt-[3px] -translate-x-1/2 -translate-y-1/2">
                <CircularProgress
                  size={30}
                  thickness={4}
                  className="text-grey-700"
                />
              </div>
            )}
          </div>
        </form>
      </section>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={extraInfoDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="extra-info-dialog-title"
      >
        <DialogTitle id="extra-info-dialog-title">Please note</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Based on the number of sites you've selected, this estimate will be
            subject to a feasibility investigation by the Solutions Architecture
            team.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleDialogClose();
              navigate("/home");
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={feasibilityFailedDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="feasibility-failed-dialog-title"
      >
        <DialogTitle id="feasibility-failed-dialog-title">
          Please note
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            One or more of the selected customer sites did not pass the
            feasibility check. This estimate will be subject to a feasibility
            investigation by the Solutions Architecture team.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleDialogClose();
              handleContinue();
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}
