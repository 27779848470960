import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

// MUI Imports
import {
  Stepper,
  Step,
  StepLabel,
  StepIcon,
  InputAdornment,
  MenuItem,
  ListItemText,
  Button,
  Slide,
  Snackbar,
} from "@mui/material";

// Helper Imports
import { snackSettings } from "../../../helpers/helpers";

import { DataGrid } from "@mui/x-data-grid";

// Custom Component Imports
import BackButton from "../../../components/back-button/back-button-component";
import InputField from "../../../components/input/input-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";
import CustomCheckbox from "../../../components/checkbox/checkbox-component";

// Icon Imports
import SearchIcon from "../../../assets/icons/search-icon.svg";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import networkTowerIcon from "../../../assets/icons/network-tower-icon.svg";
import arrowRightIcon from "../../../assets/icons/arrow-right-icon.svg";

// Steps Import
import { newOpportunitySteps } from "../step-1-opportunity/step-1-opportunity-page";

// Mock Data Imports
import {
  productFiltersOptions,
  productsMockData,
  productSortOptions,
} from "../../../data/mock-data";
import { useDispatch } from "react-redux";
import { clearSiteData } from "../../../slices/feasibility-check";
import OpportunityService from "../../../services/opportunity.service";
import AuthService from "../../../services/auth.service";
//

export default function Step2Product() {
  const { opportunityID } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (!opportunityID) {
    navigate("/home");
  } else {
    // Update Opportunity Step
    OpportunityService.updateOpportunityStep(opportunityID, "Product").catch(
      (error) => {
        handleServerError(error);
      }
    );
  }

  // Table Data
  const [productData, setProductData] = useState([]);

  // API Call for Data
  useEffect(() => {
    const fetchData = () => {
      const response = productsMockData;
      setProductData(response);
      dispatch(clearSiteData());
    };

    fetchData();
  }, []);

  // Data Grid States
  const [pageSize, setPageSize] = useState(10);
  const [filteredRows, setFilteredRows] = useState([]);

  // Opportunities Table Columns
  const productsColumns = [
    {
      field: "product_name",
      headerName: "",
      flex: 1,
      minWidth: "100%",
      renderCell: (params) => (
        <Link
          onClick={() => handleSelectProduct(params.row)}
          to={`/new-opportunity/${opportunityID}/requirements/${params.value.toLowerCase()}`}
          className="flex h-full w-full cursor-pointer items-center justify-between gap-6 text-black"
        >
          <div className="flex items-center justify-between gap-x-4">
            <InlineSVG
              src={networkTowerIcon}
              width={24}
              height={24}
              ariaHidden
              className="fill-black"
            />
            <p className="px-4">{params.value}</p>
          </div>
          <InlineSVG
            src={arrowRightIcon}
            width={24}
            height={24}
            ariaHidden
            className="fill-red-100"
          />
        </Link>
      ),
    },
  ];

  // Opportunities Table Options
  const productsTable = {
    rows: filteredRows,
    columns: productsColumns,
    pageSize: pageSize,
    rowsPerPageOptions: [10, 20, 30, 40, 50],
    onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
    disableSelectionOnClick: true,
    autoHeight: true,
    components: {
      NoRowsOverlay: () => {},
    },
  };
  // DataGrid Table Search
  const [searchText, setSearchText] = useState("");
  const [filterBy, setFilterBy] = useState([]);
  const [sortBy, setSortBy] = useState([]);

  useEffect(() => {
    let filteredData = productsMockData;

    // Filter rows by search text
    if (searchText) {
      filteredData = productData.filter((row) => {
        return (
          Object.values(row)
            .join("")
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) !== -1
        );
      });
    }

    // Filter rows by selected filters
    if (filterBy.length > 0) {
      filteredData = filteredData.filter((row) => {
        // Iterate over filterBy options and check if any row values contain the option
        for (let option of filterBy) {
          if (
            Object.values(row)
              .join("")
              .toLowerCase()
              .includes(option.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    }

    // sort rows by selected filters
    if (sortBy.length > 0) {
      // TODO: Implement sorting logic
    }

    setFilteredRows(filteredData);
  }, [searchText, filterBy, productData, sortBy]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  // handle filter by change event
  const handleFilterByChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setFilterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    setFilterBy(filteredOptions);
  };

  // handle sort by change event
  const handleSortByChange = (event) => {
    const { value } = event.target;
    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setSortBy([]);
      return;
    }
    const sortedOptions = Array.isArray(value) ? value : [value];
    setSortBy(sortedOptions);
  };

  // store the selected product in the state
  const handleSelectProduct = (product) => {
    // store the selected product in store
  };

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 400) {
      // Warning
      handleGenericSnackOpen("warning", errors[0].message);
    } else if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/");
    } else if (status === 403) {
      // Unauthorized
      handleGenericSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleGenericSnackOpen("notFound", errors[0].message);
    } else {
      if (errors) {
        // Unexpected server error
        handleGenericSnackOpen("error", errors[0].message);
      } else {
        handleGenericSnackOpen(
          "error",
          `Unexpected server error. Code: {${error.code}}; Message: {${error.message}}`
        );
      }
    }
  };

  return (
    <>
      <div className="container col-start-1 col-end-13 row-start-1 my-6 mx-auto px-4 lg:col-start-2 lg:col-end-12 lg:my-8 lg:px-0">
        <BackButton
          to={`/new-opportunity/${opportunityID}/opportunity`}
        ></BackButton>
      </div>

      <section
        aria-label="Step 2 - Product"
        className="container col-start-1 col-end-13 row-start-2 mx-auto px-4 lg:col-start-2 lg:col-end-12 lg:px-0"
      >
        <div className="my-8 grid grid-cols-10">
          <Stepper
            activeStep={1}
            alternativeLabel
            className="col-start-1 col-end-11 lg:col-start-2 lg:col-end-10"
          >
            {newOpportunitySteps.map((label) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={(props) => (
                    <StepIcon
                      {...props}
                      active={props.active || props.completed}
                      completed={false}
                    />
                  )}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>

        <div className="my-6 grid grid-cols-10 gap-4 lg:my-12">
          <div className="col-start-1 col-end-11 rounded-2xl bg-white p-4 shadow md:p-8 lg:col-start-2 lg:col-end-10 lg:p-10 xl:p-12">
            <div className="mb-6 grid grid-cols-12 gap-x-4 gap-y-6 p-2 sm:p-0">
              <div className="col-span-12 md:col-span-6 lg:col-span-7 xl:col-span-8">
                <InputField
                  inputLabel="Search"
                  value={searchText}
                  onChange={handleSearch}
                  placeholder="Search products..."
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <InlineSVG
                          src={SearchIcon}
                          width={24}
                          height={24}
                          ariaHidden
                          className="fill-black"
                        />
                      </InputAdornment>
                    ),
                  }}
                ></InputField>
              </div>
              <div className="col-span-12 md:col-span-4 lg:col-span-3 xl:col-span-2">
                <InputField
                  select
                  inputLabel="Sort by"
                  value={sortBy}
                  SelectProps={{
                    multiple: true,
                    IconComponent: (props) => (
                      <ArrowDropDownRoundedIcon {...props} />
                    ),

                    onChange: handleSortByChange,

                    renderValue: (selected) =>
                      selected.length > 0 ? selected.join(", ") : "All",
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="" className="px-6">
                    All
                  </MenuItem>
                  {productSortOptions.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      <CustomCheckbox checked={sortBy.indexOf(value) > -1} />
                      <ListItemText primary={label} />
                    </MenuItem>
                  ))}
                </InputField>
              </div>
              <div className="col-span-12 md:col-span-4 lg:col-span-2 xl:col-span-2">
                <InputField
                  select
                  inputLabel="Filter by"
                  value={filterBy}
                  SelectProps={{
                    multiple: true,
                    IconComponent: (props) => (
                      <ArrowDropDownRoundedIcon {...props} />
                    ),
                    onChange: handleFilterByChange,
                    renderValue: (selected) =>
                      selected.length > 0 ? selected.join(", ") : "All",
                    displayEmpty: true,
                  }}
                >
                  <MenuItem value="" className="px-6">
                    All
                  </MenuItem>
                  {productFiltersOptions.map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                      <CustomCheckbox checked={filterBy.indexOf(value) > -1} />
                      <ListItemText primary={label} />
                    </MenuItem>
                  ))}
                </InputField>
              </div>
            </div>
            <DataGrid
              {...productsTable}
              className="table-hide-headers border-0 text-base text-black"
            />
          </div>
        </div>
      </section>

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}
