// React Imports
import { useId } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Component Imports
import InputField from "../input/input-component";
import InlineSVG from "../../components/inline-svg/inline-svg-component";

// Icon Import
import CalendarIcon from "../../assets/icons/calendar-icon.svg";

export default function CustomDatePicker(props) {
  // Props
  const { value = null, minDate, maxDate, handleDateChange } = props;
  // Unique ID
  const uid = useId();

  // Datepicker icon
  const Calendar = () => <InlineSVG src={CalendarIcon} ariaHidden />;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        {...props}
        value={value}
        onChange={handleDateChange}
        components={{
          OpenPickerIcon: Calendar,
        }}
        minDate={minDate}
        maxDate={maxDate}
        inputFormat="DD/MM/YYYY"
        renderInput={({ label = null, inputRef, inputProps, InputProps }) => (
          <InputField
            id={uid + "expiryDateID"}
            inputLabel={label}
            InputProps={{
              ref: inputRef,
              ...inputProps,
              ...InputProps,
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
