import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";

// MUI Imports
import {
  Tabs,
  Tab,
  Button,
  InputAdornment,
  MenuItem,
  ListItemText,
  Slide,
  Snackbar,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

// Custom Component Imports
import TabPanel, {
  a11yProps,
} from "../../components/tab-panel/tab-panel-component";
import InlineSVG from "../../components/inline-svg/inline-svg-component";
import InputField from "../../components/input/input-component";
import CustomCheckbox from "../../components/checkbox/checkbox-component";
import StatusChip from "../../components/status-chip/status-chip";
import MoreOptions from "../../components/more-options/more-options-component";

// Helper Imports
import { getSeverityFromStatus, snackSettings } from "../../helpers/helpers";

// Icon Imports
import PlusIcon from "../../assets/icons/plus-icon.svg";
import SearchIcon from "../../assets/icons/search-icon.svg";
import EyeIcon from "../../assets/icons/eye-icon.svg";
import DownloadIcon from "../../assets/icons/download-icon.svg";
import MailIcon from "../../assets/icons/mail-icon.svg";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";

// Animation Imports
import Lottie from "lottie-react";
import AstronautAnimation from "../../assets/animations/astronaut-animation.json";

// Mock Data Import
import {
  //filterOptionsMockData,
  opportunityMockData,
} from "../../data/mock-data";
import OpportunityService from "../../services/opportunity.service";
import AuthService from "../../services/auth.service";
import Quote from "../../components/quote/quote";
//
const filterOptionsMockData = [
  "initiating",
  "initiated",
  "estimating",
  "estimated",
  "studying",
  "studied",
  "quoting",
  "quoted",
  "ordering",
  "ordered",
];

// Opportunities Table Columns
const opportunitiesColumns = [
  {
    field: "opportunityID",
    headerName: "ID",
    headerClassName: "text-base font-medium text-black",
    flex: 0.5,
    minWidth: 120,
    renderCell: (params) => {
      let url = "";
      switch (params.row.step) {
        case "Opportunity":
          url = `/new-opportunity/${params.value}/opportunity`;
          break;
        case "Product":
          url = `/new-opportunity/${params.value}/product`;
          break;
        case "Requirements":
          url = `/new-opportunity/${params.value}/requirements/sd-wan`;
          break;
        case "Estimate":
          url = `/new-opportunity/${params.value}/estimate/sd-wan`;
          break;
      }

      return (
        <a href={url} className="text-blue-200 underline hover:text-blue-300">
          {params.value}
        </a>
      );
    },
  },
  {
    field: "opportunityDisplayID",
    headerName: "Opportunity Ref. ID",
    headerClassName: "text-base font-medium text-black",
    flex: 1,
    minWidth: 240,
  },
  {
    field: "customerName",
    headerName: "Customer",
    headerClassName: "text-base font-medium text-black",
    flex: 0.75,
    minWidth: 180,
  },
  {
    field: "opportunityName",
    headerName: "Product",
    headerClassName: "text-base font-medium text-black",
    flex: 0.75,
    minWidth: 180,
  },
  {
    field: "opportunitySize",
    headerName: "Opportunity Size",
    headerClassName: "text-base font-medium text-black",
    flex: 0.75,
    minWidth: 180,
    renderCell: (params) =>
      parseFloat(params.value).toLocaleString("en-ZA", {
        style: "currency",
        currency: "ZAR",
      }),
  },
  {
    field: "createdAt",
    headerName: "Created",
    headerClassName: "text-base font-medium text-black",
    flex: 0.75,
    minWidth: 180,
  },
  {
    field: "statusName",
    headerName: "Status",
    headerClassName: "text-base font-medium text-black",
    flex: 0.5,
    minWidth: 140,
    renderCell: (params) => (
      <StatusChip
        severity={getSeverityFromStatus(params.value)}
        label={params.value}
      />
    ),
  },
];

export default function Home() {
  const navigate = useNavigate();

  // Tabs
  const [value, setValue] = useState(0);
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  // New Opportunity Button Click
  const onNewOpportunityButtonClick = () => {
    navigate("/new-opportunity/opportunity");
  };

  // Table Data
  const [isLoading, setIsLoading] = useState(true);
  const [opportunityData, setOpportunityData] = useState([]);

  // API Call for Data
  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      OpportunityService.getAllOpportunities()
        .then((response) => {
          setOpportunityData(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          handleServerError(error);
          setIsLoading(false);
        });
    };

    fetchData();
  }, []);

  // Data Grid States
  const [pageSize, setPageSize] = useState(10);
  const [filteredRows, setFilteredRows] = useState([]);

  // Opportunities Table Options
  const opportunitiesTable = {
    rows: filteredRows,
    columns: [
      ...opportunitiesColumns,
      {
        field: "actions",
        type: "actions",
        width: 60,
        renderCell: (params) => {
          return (
            <MoreOptions orientation="vertical" disablePortal={false}>
              <MenuItem
                className="flex items-center gap-x-2"
                disableRipple
                onClick={() => {
                  window.open(
                    `/new-opportunity/${params.row.opportunityID}/customer-quote`,
                    "_blank"
                  );
                }}
              >
                <InlineSVG
                  src={EyeIcon}
                  width={24}
                  height={24}
                  className="fill-red-100"
                  ariaHidden
                />
                Show customer quote
              </MenuItem>
              {/* <MenuItem className="flex items-center gap-x-2" disableRipple>
              <PDFDownloadLink
                document={<Quote />}
                fileName="customer_quote.pdf"
              >
                <InlineSVG
                  src={DownloadIcon}
                  width={24}
                  height={24}
                  className="fill-red-100"
                  ariaHidden
                />
                Download quote
              </PDFDownloadLink>
            </MenuItem> */}
            </MoreOptions>
          );
        },
      },
    ],
    pageSize: pageSize,
    rowsPerPageOptions: [10, 20, 30, 40, 50],
    onPageSizeChange: (newPageSize) => setPageSize(newPageSize),
    disableSelectionOnClick: true,
    autoHeight: true,
    components: {
      NoRowsOverlay: () => {},
    },
  };

  // DataGrid Table Search
  const [searchText, setSearchText] = useState("");
  const [filterBy, setFilterBy] = useState([]);

  useEffect(() => {
    let filteredData = opportunityData;

    // Filter rows by search text
    if (searchText) {
      filteredData = opportunityData.filter((row) => {
        return (
          Object.values(row)
            .join("")
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) !== -1
        );
      });
    }

    // Filter rows by selected filters
    if (filterBy.length > 0) {
      filteredData = filteredData.filter((row) => {
        // Iterate over filterBy options and check if any row values contain the option
        for (let option of filterBy) {
          if (
            Object.values(row)
              .join("")
              .toLowerCase()
              .includes(option.toLowerCase())
          ) {
            return true;
          }
        }
        return false;
      });
    }

    setFilteredRows(filteredData);
  }, [searchText, filterBy, opportunityData]);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleFilterByChange = (event) => {
    const { value } = event.target;

    // Check if "All" is selected and remove the rest of the filters
    if (value.includes("") || value.length === 0) {
      setFilterBy([]);
      return;
    }

    const filteredOptions = Array.isArray(value) ? value : [value];
    setFilterBy(filteredOptions);
  };

  // Snackbar state
  const [genericSnackMessage, setGenericSnackMessage] = useState("");
  const [genericSnack, setGenericSnack] = useState(false);
  const [snackType, setSnackType] = useState("success");

  const handleGenericSnackOpen = (type, message) => {
    setSnackType(type);
    setGenericSnack(true);
    setGenericSnackMessage(message);
  };

  const handleGenericSnackClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGenericSnack(false);
  };

  const handleServerError = (error) => {
    const { status } = error.response;
    const { errors } = error.response.data;

    if (status === 400) {
      // Warning
      handleGenericSnackOpen("warning", errors[0].message);
    } else if (status === 401) {
      // Unauthenticated
      AuthService.logout();
      navigate("/");
    } else if (status === 403) {
      // Unauthorized
      handleGenericSnackOpen("unauthorized", errors[0].message);
    } else if (status === 404) {
      // Content not found
      handleGenericSnackOpen("notFound", errors[0].message);
    } else {
      if (errors) {
        // Unexpected server error
        handleGenericSnackOpen("error", errors[0].message);
      } else {
        handleGenericSnackOpen(
          "error",
          `Unexpected server error. Code: {${error.code}}; Message: {${error.message}}`
        );
      }
    }
  };

  return (
    <>
      <section
        aria-label="Business Portal Dashboard"
        className="container col-start-1 col-end-13 row-start-1 mx-auto px-4 lg:col-start-2 lg:col-end-12 lg:px-0"
      >
        <div className="mt-6 mb-4 text-center sm:text-end md:my-8">
          <Button
            variant="contained"
            startIcon={
              <InlineSVG
                src={PlusIcon}
                width={24}
                height={24}
                ariaHidden
                className="fill-white"
              />
            }
            onClick={onNewOpportunityButtonClick}
            disableFocusRipple
          >
            Create new opportunity
          </Button>
        </div>

        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Dashboard Tabs"
          className="my-8"
        >
          <Tab label="Opportunities" {...a11yProps(0)} disableRipple />
          <Tab label="Estimates" {...a11yProps(1)} disableRipple />
          <Tab label="Quotes" {...a11yProps(2)} disableRipple />
          <Tab label="Orders" {...a11yProps(3)} disableRipple />
        </Tabs>

        <TabPanel value={value} index={0}>
          <div className="mb-6 grid grid-cols-10 gap-x-4">
            <div className="col-span-10 sm:col-span-5 md:col-span-6 lg:col-span-7 xl:col-span-8">
              <InputField
                inputLabel="Search"
                value={searchText}
                onChange={handleSearch}
                placeholder="Search..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <InlineSVG
                        src={SearchIcon}
                        width={24}
                        height={24}
                        ariaHidden
                        className="fill-black"
                      />
                    </InputAdornment>
                  ),
                }}
              ></InputField>
            </div>
            <div className="col-span-10 mt-6 sm:col-span-5 sm:mt-0 md:col-span-4 lg:col-span-3 xl:col-span-2">
              <InputField
                select
                inputLabel="Filter by"
                value={filterBy}
                SelectProps={{
                  multiple: true,
                  IconComponent: (props) => (
                    <ArrowDropDownRoundedIcon {...props} />
                  ),
                  onChange: handleFilterByChange,
                  renderValue: (selected) =>
                    selected.length > 0 ? selected.join(", ") : "All",
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="px-6">
                  All
                </MenuItem>
                {filterOptionsMockData.map((option) => (
                  <MenuItem key={option} value={option}>
                    <CustomCheckbox checked={filterBy.indexOf(option) > -1} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ))}
              </InputField>
            </div>
          </div>

          <DataGrid
            getRowId={(row) => row.opportunityID}
            loading={isLoading}
            {...opportunitiesTable}
            className="mb-12 rounded-2xl border-0 bg-white px-4 pt-8 pb-4 text-base text-black shadow sm:px-6 md:px-10"
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="flex flex-col items-center justify-center pb-6 lg:pb-12">
            <Lottie
              animationData={AstronautAnimation}
              aria-hidden="true"
              role="presentation"
              className="max-w-2xl"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <div className="flex flex-col items-center justify-center pb-6 lg:pb-12">
            <Lottie
              animationData={AstronautAnimation}
              aria-hidden="true"
              role="presentation"
              className="max-w-2xl"
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <div className="flex flex-col items-center justify-center pb-6 lg:pb-12">
            <Lottie
              animationData={AstronautAnimation}
              aria-hidden="true"
              role="presentation"
              className="max-w-2xl"
            />
          </div>
        </TabPanel>
      </section>

      <Snackbar
        open={genericSnack}
        autoHideDuration={6000}
        onClose={handleGenericSnackClose}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        ContentProps={{
          className: snackSettings[snackType].colorClass,
        }}
        message={
          <div className="flex items-center gap-3">
            <InlineSVG
              src={snackSettings[snackType].icon}
              ariaHidden
              width={28}
              height={28}
              className="fill-white"
            />
            <p className="mb-0 text-base text-white">{genericSnackMessage}</p>
          </div>
        }
        action={
          <Button
            variant="text"
            className="ml-auto text-white hover:text-white sm:ml-8 md:ml-12 lg:ml-16"
            onClick={handleGenericSnackClose}
            disableFocusRipple
          >
            Dismiss
          </Button>
        }
      />
    </>
  );
}
