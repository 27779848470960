import axios from "axios";

const API_URL = "/api/auth/";

const login = (email, password) => {
  return axios
    .post(API_URL + "login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.email) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
  return axios.post(API_URL + "logout").then((response) => {
    return response.data;
  });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getCurrentUserAPI = () => {
  return axios.get(API_URL + "currentuser")
  .then((response) => {
    const { currentUser } = response.data;
    if (currentUser !== null) {
      localStorage.setItem("user", JSON.stringify(currentUser));
    }
    return response.data;
  });
};


const AuthService = {
  login,
  logout,
  getCurrentUser,
  getCurrentUserAPI
};

export default AuthService;
