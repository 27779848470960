import { useId, useState } from "react";
import { useNavigate } from "react-router-dom";

// MUI Imports
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  useMediaQuery,
} from "@mui/material";

// Custom Component Imports
import LeafletMap from "../leaflet-map/leaflet-map-component";
import CustomCheckbox from "../checkbox/checkbox-component";
import InlineSVG from "../inline-svg/inline-svg-component";

// Icon Imports
import ArrowRightIcon from "../../assets/icons/arrow-right-icon.svg";
import DeleteIcon from "../../assets/icons/delete-solid-icon.svg";
import ClockIcon from "../../assets/icons/clock-solid-icon.svg";
import TickIcon from "../../assets/icons/tick-circle-icon.svg";
import ErrorIcon from "../../assets/icons/error-circle-icon.svg";

const FeasibilitySiteCard = ({
  opportunityID,
  product,
  id,
  siteName,
  hubSite,
  setHubSite,
  passed,
  mapPosition,
  deleteSite,
}) => {
  const navigate = useNavigate();
  const uid = useId();

  // Route to feasibility check with ID
  const handleFeasibilityCheckClick = () => {
    navigate(`/${opportunityID}/feasibility-check/${product}/${id}`);
  };

  // Dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  // Handle open delete dialog when a feasibility check has already happened
  const handleOpenDeleteDialog = () => {
    if (passed != null) {
      setDeleteDialogOpen(true);
    } else {
      handleDeleteSite(id);
    }
  };

  // Handle dialog close event
  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  // Handle delete site
  const handleDeleteSite = () => {
    deleteSite(id);
  };

  return (
    <>
      <article className="relative flex h-60 w-full flex-col justify-between gap-4 rounded border border-grey-100">
        <div className="relative z-10 flex items-center justify-between p-2">
          <div className="p-2">
            {passed == null ? (
              <InlineSVG
                src={ClockIcon}
                width={24}
                height={24}
                ariaHidden
                className="fill-grey-200"
              />
            ) : (
              <InlineSVG
                src={passed ? TickIcon : ErrorIcon}
                width={24}
                height={24}
                ariaHidden
                className={passed ? "fill-green-100" : "fill-yellow-200"}
              />
            )}
          </div>

          <IconButton
            className="fill-yellow-200 hover:fill-yellow-800"
            aria-label="Delete site"
            onClick={handleOpenDeleteDialog}
          >
            <InlineSVG src={DeleteIcon} width={24} height={24} ariaHidden />
          </IconButton>
        </div>

        <div className="relative z-10 text-center">
          <h2 className="text-2xl font-bold text-black">{siteName}</h2>
        </div>

        <div className="relative z-10 flex items-center justify-between px-6 py-4">
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={hubSite}
                onChange={() => setHubSite(!hubSite)}
              />
            }
            label="Hub Site"
          />

          <Button
            variant="text"
            className="fill-red-100 p-0 text-red-100 hover:fill-grey-700"
            endIcon={
              <InlineSVG
                src={ArrowRightIcon}
                width={24}
                height={24}
                ariaHidden
              />
            }
            disableRipple
            onClick={handleFeasibilityCheckClick}
          >
            Feasibility Check
          </Button>
        </div>

        <div className="absolute top-0 left-0 z-0 h-full w-full overflow-hidden rounded">
          <div className="absolute bottom-0 z-[1024] h-full w-full bg-gradient-to-t from-white to-transparent"></div>
          <LeafletMap
            position={mapPosition}
            zoom={16}
            controls={false}
            marker={false}
          />
        </div>
      </article>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby={uid + "delete-dialog-title"}
      >
        <DialogTitle id={uid + "delete-dialog-title"}>
          Delete this site?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            A feasibility check has already been performed on this site, are you
            sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            No, take me back
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={() => {
              handleDialogClose();
              handleDeleteSite(id);
            }}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FeasibilitySiteCard;
