import React, { Fragment } from "react";
import {
  Image,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import logo from "../../assets/vodafone-logo.png";
import { Table } from "@mui/material";

export default function Quote({ quoteData }) {
  // const quoteData = {
  //   id: "642be0b4bbe5d71a5341dfb1",
  //   quoteNumber: "OPP-0000000000058",
  //   address:
  //     "Unit A1, Spearhead Business Park, Montague Gardens, Cape Town, 7441",
  //   date: "27-05-2024",
  //   items: [
  //     {
  //       term: 12,
  //       lineItems: [
  //         {
  //           itemGroup: "Site L1",
  //           items: [
  //             {
  //               desc: "Meraki MX50",
  //             },
  //             {
  //               desc: "MX50-RMA",
  //             },
  //             {
  //               desc: "Meraki MX50 Advanced Security License and Support, 1YR",
  //             },
  //             {
  //               desc: "SDWAN Connect 50Mbps",
  //             },
  //             {
  //               desc: "SDWAN Management Fee",
  //             },
  //           ],
  //         },
  //         {
  //           itemGroup: "Site L2",
  //           items: [
  //             {
  //               desc: "Meraki MX50",
  //             },
  //             {
  //               desc: "MX50-RMA",
  //             },
  //             {
  //               desc: "Meraki MX50 Advanced Security License and Support, 1YR",
  //             },
  //             {
  //               desc: "SDWAN Connect 50Mbps",
  //             },
  //             {
  //               desc: "SDWAN Management Fee",
  //             },
  //           ],
  //         },
  //       ],
  //       totals: [
  //         { description: "Discount", value: 0 },
  //         { description: "Sub-Total", value: 30592.55 },
  //         { description: "Vat", value: 4588.88 },
  //         { description: "Total", value: 35181.43 },
  //       ],
  //     },
  //     {
  //       term: 24,
  //       lineItems: [
  //         {
  //           itemGroup: "Site L1",
  //           items: [
  //             {
  //               desc: "Meraki MX50",
  //             },
  //             {
  //               desc: "MX50-RMA",
  //             },
  //             {
  //               desc: "Meraki MX50 Advanced Security License and Support, 1YR",
  //             },
  //             {
  //               desc: "SDWAN Connect 50Mbps",
  //             },
  //             {
  //               desc: "SDWAN Management Fee",
  //             },
  //           ],
  //         },
  //         {
  //           itemGroup: "Site L2",
  //           items: [
  //             {
  //               desc: "Meraki MX50",
  //             },
  //             {
  //               desc: "MX50-RMA",
  //             },
  //             {
  //               desc: "Meraki MX50 Advanced Security License and Support, 1YR",
  //             },
  //             {
  //               desc: "SDWAN Connect 50Mbps",
  //             },
  //             {
  //               desc: "SDWAN Management Fee",
  //             },
  //           ],
  //         },
  //       ],
  //       totals: [
  //         { description: "Discount", value: 0 },
  //         { description: "Sub-Total", value: 30592.55 },
  //         { description: "Vat", value: 4588.88 },
  //         { description: "Total", value: 35181.43 },
  //       ],
  //     },
  //   ],
  // };

  if (!quoteData) return null;

  const terms = quoteData.items?.map((item) => item.term);

  const styles = StyleSheet.create({
    page: {
      fontSize: 11,
      paddingTop: 20,
      paddingLeft: 40,
      paddingRight: 40,
      paddingBottom: 20,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    spaceBetween: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      color: "#3E3E3E",
    },
    titleContainer: {
      flexDirection: "row",
      marginTop: 24,
    },
    reportTitle: { fontSize: 16, textAlign: "center" },
    logo: { width: 90 },
    addressTitle: { fontSize: 11, fontStyle: "bold" },
    invoice: { fontWeight: "bold", fontSize: 20 },
    invoiceNumber: { fontSize: 11, fontWeight: "bold" },
    address: { fontWeight: 400, fontSize: 10 },
    theader: {
      marginTop: 20,
      fontSize: 10,
      fontStyle: "bold",
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      height: 20,
      backgroundColor: "#DEDEDE",
      borderColor: "#E6E6E6",
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },
    theader2: {
      flex: 2,
      borderColor: "#E6E6E6",
      borderLeftWidth: 1,
      borderBottomWidth: 1,
    },
    tbody: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      borderColor: "#E6E6E6",
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },
    total: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1.5,
      borderColor: "#E6E6E6",
      borderBottomWidth: 1,
    },
    tbody2: { flex: 2, borderColor: "#E6E6E6", borderLeftWidth: 1 },
    tbody3: { flex: 2, borderRightWidth: 1, fontWeight: 500 },
    tfooter: {
      fontSize: 10,
      fontStyle: "bold",
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      height: 20,
      backgroundColor: "#DEDEDE",
      borderColor: "#E6E6E6",
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  const InvoiceTitle = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <Image style={styles.logo} src={logo} />
        <Text style={styles.reportTitle}>Vodacom Business</Text>
      </View>
    </View>
  );

  const Address = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View>
          <Text style={styles.invoice}>Proforma Quote </Text>
          <Text style={styles.invoiceNumber}>
            Quote Number: {quoteData.quoteNumber}{" "}
          </Text>
        </View>
        <View>
          <Text style={styles.addressTitle}>Vodacom Corporate Park</Text>
          <Text style={styles.addressTitle}>082 Vodacom Boulevard</Text>
          <Text style={styles.addressTitle}>Midrand, South Africa</Text>
        </View>
      </View>
    </View>
  );

  const UserAddress = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View style={{ maxWidth: 200 }}>
          <Text style={styles.addressTitle}>Quote to </Text>
          <Text style={styles.address}>{quoteData.address || ""}</Text>
        </View>
        <Text style={styles.addressTitle}>{quoteData.date}</Text>
      </View>
    </View>
  );

  const TableHead = ({ term }) => (
    <View style={{ width: "100%", flexDirection: "row", marginTop: 10 }}>
      <View style={[styles.theader, styles.theader2]}>
        <Text>Description</Text>
      </View>
      <View style={styles.theader}>
        <Text>{term} Months</Text>
      </View>
    </View>
  );

  const TableBody = ({ lineItems }) =>
    lineItems?.map((lineItem, index) => (
      <Fragment key={`tableBodyFragment_${index}`}>
        <View style={{ width: "100%", flexDirection: "row" }}>
          <View style={[styles.tbody, styles.tbody2]}>
            <Text>
              {lineItem?.header ? "(" : ""}
              {lineItem?.desc}
              {lineItem?.header ? "):" : ""}{" "}
            </Text>
          </View>
          <View style={styles.tbody}>
            <Text> </Text>
          </View>
        </View>
      </Fragment>
    ));

  const TableTotal = ({ totals }) =>
    totals?.map((total, index) => (
      <Fragment key={`tableTotalFragment_${index}`}>
        <View style={{ width: "100%", flexDirection: "row" }}>
          <View style={[styles.tfooter, styles.theader2]}>
            <Text>{total.description}</Text>
          </View>
          <View style={styles.tfooter}>
            <Text>
              {total.value.toLocaleString("en-ZA", {
                style: "currency",
                currency: "ZAR",
              })}
            </Text>
          </View>
        </View>
      </Fragment>
    ));

  const Notes = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View style={{ width: "100%" }}>
          <Text style={styles.addressTitle}>Notes:</Text>
          <Text style={styles.address}>
            All Pricing is budgetary and should not be put on Siebel as it is
            indicative pricing to allow customers to understand the estimated
            amount to be spent. Prices are fully subject to site surveys being
            done on the sign off, of this budgetary quote
          </Text>
          <Text style={styles.address}>Prices exclude VAT</Text>
          <Text style={styles.address}>Prices are Valid for 14 days</Text>
          <Text style={styles.address}>
            The above is not an official quote and should be used for budgetary
            purposes only
          </Text>
          <Text style={styles.address}>
            BOM is based on a Predictive survey which by nature is for budgetary
            purposes only.
          </Text>
          <Text style={styles.address}>
            An accurate quote can only be given once an on-site survey has been
            completed
          </Text>
          <Text style={styles.address}>
            Prices linked to an ROE is indicative and will be subject to
            fluctuations upon official quoting
          </Text>
          <Text style={styles.address}>
            This quote excludes all Fibre Cabling
          </Text>
          <Text style={styles.address}>
            Price excludes any Civils, cherry pickers, specialist climbing
            equipment
          </Text>
          <Text style={styles.address}>
            Price assumes that all Access Point locations can be reached with a
            standard 3 Meter ladder.
          </Text>
          <Text style={styles.address}>
            This quote excludes the cost of wiring cabinets and/or brush panels
            and associated power points.
          </Text>
          <Text style={styles.address}>Price excludes after-hours work.</Text>
          <Text style={styles.address}>
            The pricing provided for cabling is for budgetary purposes only.
          </Text>
        </View>
      </View>
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <InvoiceTitle />
        <Address />
        <UserAddress />
        {terms.map((term, index) => {
          const termData = quoteData.items.find((item) => item.term === term);
          if (!termData) return null;

          // Table Body Data
          const { lineItems } = termData;
          const lineItemData = lineItems?.map((lineItem) => {
            const { itemGroup, items } = lineItem;
            const header = [{ desc: itemGroup, header: true }];
            return [...header, ...items];
          });

          // Table Total Data
          const { totals } = termData;

          return (
            <Fragment key={`termFragment_${index}`}>
              <TableHead key={`tableHead_${index}`} term={term} />
              {lineItemData.map((lineItems, i) => (
                <TableBody key={`tableBody_${i}`} lineItems={lineItems} />
              ))}
              <TableTotal key={`tableTotal_${index}`} totals={totals} />
            </Fragment>
          );
        })}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
      <Page size="A4" style={styles.page}>
        <Notes />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
        />
      </Page>
    </Document>
  );
}
