import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerBudget: 0,
  terms: [],
  siteData: [],
  dataLoaded: false,
  opportunity: {},
};

export const feasibilitySlice = createSlice({
  name: "feasibility-check",
  initialState,
  reducers: {
    updateSiteData: (state, action) => {
      const { id, data } = action.payload;
      const index = state.siteData.findIndex((site) => site.id == id);
      console.log(action.payload, state.siteData, index);
      if (index !== -1) {
        state.siteData[index] = {
          ...state.siteData[index],
          ...data,
        };

        state.siteData = [
          ...state.siteData.slice(0, index),
          { ...state.siteData[index], ...data },
          ...state.siteData.slice(index + 1),
        ];
      }
    },
    deleteSite: (state, action) => {
      const id = action.payload;
      state.siteData = state.siteData.filter((site) => site.id !== id);
    },
    addSite: (state, action) => {
      const newSite = action.payload;
      // Skip if site exists
      const site = state.siteData.find((site) => site.id === newSite.id);
      if (!site) {
        state.siteData.push(newSite);
      }
    },
    updateCustomerBudget: (state, action) => {
      state.customerBudget = action.payload;
    },
    updateTerms: (state, action) => {
      state.terms = action.payload;
    },
    updateDataLoaded: (state, action) => {
      state.dataLoaded = action.payload;
    },
    updateOpportunity: (state, action) => {
      state.opportunity = action.payload;
    },
    clearSiteData: (state) => {
      state.siteData = [];
      state.dataLoaded = false;
      state.customerBudget = 0;
      state.terms = [];
    },
  },
});

export const {
  updateSiteData,
  deleteSite,
  addSite,
  updateCustomerBudget,
  updateTerms,
  updateDataLoaded,
  updateOpportunity,
  clearSiteData,
} = feasibilitySlice.actions;

// Selectors
export const getCustomerBudget = createSelector(
  (state) => state.feasibilityCheck.customerBudget,
  (customerBudget) => customerBudget
);

export const getTerms = createSelector(
  (state) => state.feasibilityCheck.terms,
  (terms) => terms
);

export const getOpportunity = createSelector(
  (state) => state.feasibilityCheck.opportunity,
  (opportunity) => opportunity
);

export const getDataLoaded = createSelector(
  (state) => state.feasibilityCheck.dataLoaded,
  (dataLoaded) => dataLoaded
);

export const getSiteData = createSelector(
  (state) => state.feasibilityCheck.siteData,
  (siteData) => siteData
);

export default feasibilitySlice.reducer;
