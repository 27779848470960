import { useState } from "react";


// import mui components
import { Button, Tab, Tabs } from "@mui/material";
import TabPanel, {
  a11yProps,
} from "../../../components/tab-panel/tab-panel-component";

// import icons

import mailIcon from "../../../assets/icons/mail-icon.svg";
import printIcon from "../../../assets/icons/print-icon.svg";

// import mock data
import { customerViewMockData } from "../../../data/mock-data";

// import custom components
import CustomerViewTable from "../../../components/customer-view-table/customer-view-table";
import BackButton from "../../../components/back-button/back-button-component";
import InlineSVG from "../../../components/inline-svg/inline-svg-component";

export default function CustomerView() {
  // tab value
  const [value, setValue] = useState(0);

  // mock data
  const [productData, setProductData] = useState(customerViewMockData || []);

  // mock filter to get data for 12 and 24 months
  const filteredRows = productData.filter((product) =>
    value === 0 ? product.term === "12 Months" : product.term === "24 Months"
  );

  // handle tab change
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  // handle print quote
  const handlePrintQuote = () => {
    console.log("Print quote");
  };

  // handle email quote
  const handleEmailQuote = () => {
    console.log("Email quote");
  };

  return (
    <>
      <div className="container col-start-1 lg:col-start-2 xl:col-start-3 col-end-13 lg:col-end-12 xl:col-end-11 px-4 lg:px-0 row-start-1 my-6 lg:my-8 mx-auto">
        <BackButton />
      </div>

      <section
        aria-label="Customer view"
        className="container col-start-1 lg:col-start-2 xl:col-start-3 col-end-13 lg:col-end-12 xl:col-end-11 row-start-2 px-4 lg:px-0 mx-auto"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Product Term tabs"
          className="mt-8 mb-6 px-2"
        >
          <Tab label="12 Months" {...a11yProps(0)} disableRipple />
          <Tab label="24 Months" {...a11yProps(1)} disableRipple />
        </Tabs>

        <TabPanel value={value} index={0}>
          <div className="mb-8 lg:mb-12 rounded-2xl bg-white p-6 shadow md:p-8 lg:p-10 xl:p-12">
            <div className="mb-2 flex flex-col lg:flex-row gap-4 lg:justify-end">
              <Button
                className="w-full lg:w-fit"
                variant="outlined"
                startIcon={
                  <InlineSVG
                    src={printIcon}
                    width={24}
                    height={24}
                    ariaHidden
                    className="fill-black"
                  />
                }
                disableFocusRipple
                onClick={handlePrintQuote}
              >
                Print quote
              </Button>
              <Button
                className="w-full lg:w-fit"
                variant="contained"
                color="secondary"
                startIcon={
                  <InlineSVG
                    src={mailIcon}
                    width={24}
                    height={24}
                    ariaHidden
                    className="fill-white"
                  />
                }
                disableFocusRipple
                onClick={handleEmailQuote}
              >
                Email quote
              </Button>
            </div>

            <CustomerViewTable className="mt-8 mb-4 lg:my-8" data={filteredRows} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="mb-8 lg:mb-12 rounded-2xl bg-white p-6 shadow md:p-8 lg:p-10 xl:p-12">
            <div className="mb-2 flex flex-col lg:flex-row gap-4 lg:justify-end">
              <Button
                className="w-full lg:w-fit"
                variant="outlined"
                startIcon={
                  <InlineSVG
                    src={printIcon}
                    width={24}
                    height={24}
                    ariaHidden
                    className="fill-black"
                  />
                }
                disableFocusRipple
                onClick={handlePrintQuote}
              >
                Print quote
              </Button>
              <Button
                className="w-full lg:w-fit"
                variant="contained"
                color="secondary"
                startIcon={
                  <InlineSVG
                    src={mailIcon}
                    width={24}
                    height={24}
                    ariaHidden
                    className="fill-white"
                  />
                }
                disableFocusRipple
                onClick={handleEmailQuote}
              >
                Email quote
              </Button>
            </div>

            <CustomerViewTable className="mt-8 mb-4 lg:my-8" data={filteredRows} />
          </div>
        </TabPanel>        
      </section>
    </>
  );
}
