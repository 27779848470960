import { PDFViewer } from "@react-pdf/renderer";
import Quote from "../../../components/quote/quote";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import OpportunityService from "../../../services/opportunity.service";

export default function CustomerQuote() {
  const { opportunityID } = useParams();
  const navigate = useNavigate();

  console.log(opportunityID);

  if (!opportunityID) {
    navigate("/error-404");
  }

  const [quoteData, setQuoteData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    OpportunityService.getOpportunityQuote(opportunityID).then((res) => {
      const data = res.data;
      const { id } = data;
      console.log(data);
      if (!id) {
        navigate("/error-404");
      } else {
        setQuoteData(data);
        setIsLoading(false);
      }
    });
  }, []);

  console.log(quoteData);

  return isLoading ? (
    <div>Loading...</div>
  ) : (
    <div style={{ height: "100vh" }}>
      <PDFViewer width="100%" height="100%">
        <Quote quoteData={quoteData} />
      </PDFViewer>
    </div>
  );
}
