// React and Mui Imports
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";

// Component Imports
import InlineSVG from "../inline-svg/inline-svg-component";

// Asset Imports (Icons and Logo)
import logo from "../../assets/vodafone-logo-icon.svg";
import HomeIcon from "../../assets/icons/home-outlined-icon.svg";
// import ProductIcon from "../../assets/icons/open-box-icon.svg";
// import ProfileIcon from "../../assets/icons/admin-outlined-icon.svg";
// import SettingsIcon from "../../assets/icons/settings-outlined-icon.svg";
// import GetHelpIcon from "../../assets/icons/question-outlined-icon.svg";
import LogOutIcon from "../../assets/icons/log-out-icon.svg";

// Service Imports
import AuthService from "../../services/auth.service";

export default function Navbar() {
  const navigate = useNavigate();

  // Dialogs
  const [logoutWarningDialogOpen, setLogoutWarningDialogOpen] = useState(false);
  const fullScreen = useMediaQuery("(max-width: 640px)");

  // Handle dialog close event
  const handleDialogClose = () => {
    setLogoutWarningDialogOpen(false);
  };

  // Handle dialog open event
  const handleLogoutWarningOpen = (event) => {
    event.preventDefault();
    setLogoutWarningDialogOpen(true);
  };

  // Handle logout trigger
  const handleLogout = () => {
    AuthService.logout().then(() => {
      navigate("/");
    });
  };

  return (
    <>
      <header className="sticky top-0 z-20 flex items-center bg-white px-6 py-4 shadow">
        <Link to="/home">
          <img src={logo} alt="Vodafone Logo" width={56} height={56} />
        </Link>

        <nav className="ml-auto flex items-center gap-x-6 transition-opacity">
          <NavLink
            to="/home"
            end
            className="flex items-center gap-x-3 hover:fill-red-200 hover:text-red-200"
          >
            <InlineSVG src={HomeIcon} width={28} height={28} ariaHidden />
            <span className="text-lg">Home</span>
          </NavLink>
          {/* <NavLink to="/" end className="flex items-center gap-x-3 hover:fill-red-200 hover:text-red-200">
            <InlineSVG src={ProductIcon} width={28} height={28} ariaHidden />
            <span className="text-lg">Product</span>
          </NavLink>
          <NavLink to="/" end className="flex items-center gap-x-3 hover:fill-red-200 hover:text-red-200">
            <InlineSVG src={ProfileIcon} width={28} height={28} ariaHidden />
            <span className="text-lg">Profile</span>
          </NavLink>
          <NavLink to="/" end className="flex items-center gap-x-3 hover:fill-red-200 hover:text-red-200">
            <InlineSVG src={SettingsIcon} width={28} height={28} ariaHidden />
            <span className="text-lg">Settings</span>
          </NavLink>
          <NavLink to="/" end className="flex items-center gap-x-3 hover:fill-red-200 hover:text-red-200">
            <InlineSVG src={GetHelpIcon} width={28} height={28} ariaHidden />
            <span className="text-lg">Get Help</span>
          </NavLink> */}
          <NavLink
            to="/logout"
            className="flex items-center gap-x-3 hover:fill-red-200 hover:text-red-200"
            onClick={handleLogoutWarningOpen}
          >
            <InlineSVG src={LogOutIcon} width={28} height={28} ariaHidden />
            <span className="text-lg">Sign out</span>
          </NavLink>
        </nav>
      </header>

      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        open={logoutWarningDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="logout-dialog-title"
      >
        <DialogTitle id="logout-dialog-title">Sign out?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Any unsaved data will be lost. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            disableFocusRipple
            color="secondary"
            onClick={handleDialogClose}
          >
            No, take me back
          </Button>
          <Button
            variant="contained"
            autoFocus
            disableFocusRipple
            onClick={handleLogout}
          >
            Yes, I'm sure
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
