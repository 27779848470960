//import { useState } from "react";

// MUI Imports
import { IconButton } from "@mui/material";

// Custom Component Imports
import InlineSVG from "../inline-svg/inline-svg-component";
import InputField from "../input/input-component";

// Icon Imports
import MinusIcon from "../../assets/icons/minus-icon.svg";
import PlusIcon from "../../assets/icons/plus-icon.svg";

// Masking Values Imports
import { useIMask } from "react-imask";
import { maskNumberOnlyOptions } from "../../helpers/helpers";

const PlusMinusInputField = ({
  label,
  value,
  setValue,
  disabled = false,
  showMinus = true,
  showPlus = true,
  readOnly = false,
  InputProps,
}) => {
  // Mask budget input field
  const { ref: refNumberField } = useIMask(maskNumberOnlyOptions, {
    onAccept: (value) => {
      setValue(value);
    },
  });

  // Handle minus number button click
  const handleMinusNumber = () => {
    if (value !== 0) {
      setValue(parseInt(value) - 1);
    }

    if (value === "") {
      setValue(0);
    }
  };

  // Handle plus number button click
  const handlePlusNumber = () => {
    if (value !== "") {
      setValue(parseInt(value) + 1);
    } else {
      setValue(1);
    }
  };

  // Handle onChange
  const onValueChange = (value) => {
    setValue(value);
  };

  return (
    <div className="flex items-end gap-4">
      {showMinus && (
        <IconButton
          aria-label="Minus"
          onClick={handleMinusNumber}
          className="mb-[10px] border border-black bg-white fill-black p-1 disabled:border-grey-300 disabled:fill-grey-400 hover:border-blue-200 hover:bg-white hover:fill-blue-200"
          disabled={disabled}
        >
          <InlineSVG src={MinusIcon} width={18} height={18} ariaHidden />
        </IconButton>
      )}

      <div className="flex-grow">
        <InputField
          inputLabel={label}
          inputRef={refNumberField}
          placeholder="0"
          value={value}
          onChange={(e) => onValueChange(e.target.value)}
          disabled={disabled}
          InputProps={{
            readOnly: readOnly,
            ...InputProps,
          }}
        />
      </div>

      {showPlus && (
        <IconButton
          aria-label="Plus"
          onClick={handlePlusNumber}
          className="mb-[10px] border border-black bg-white fill-black p-1 disabled:border-grey-300 disabled:fill-grey-400 hover:border-blue-200 hover:bg-white hover:fill-blue-200"
          disabled={disabled}
        >
          <InlineSVG src={PlusIcon} width={18} height={18} ariaHidden />
        </IconButton>
      )}
    </div>
  );
};

export default PlusMinusInputField;
